import React from 'react';
import BackButton from '../../components/BackButton/BackButton';
import PasswordForm from '../../components/PasswordForm/PasswordForm';

const SignUpStep4 = ({ onUserCreated }: Props) => {
  const handleUserCreate = () => {
    onUserCreated();
  };

  return (
    <section className="layout__sign-up layout__sign-up_average-form">
      <BackButton to="/sign-up/email" />
      <h1 className="layout__sign-up-title">Let&apos;s get started!</h1>
      <div className="layout__sign-up-text">Enter and confirm your password</div>
      <PasswordForm className="layout__sign-up-form" onUserCreated={handleUserCreate} />
    </section>
  );
};

type Props = {
  onUserCreated: () => void;
};

export default SignUpStep4;
