import React from 'react';
import UserDetailForm from '../../components/UserDetailForm/UserDetailForm';
import UpdateEmailForm from '../../components/UpdateEmailForm/UpdateEmailForm';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';

const MainForms = () => {
  return (
    <>
      <UserDetailForm className="mb4" />
      <UpdateEmailForm className="mb4" />
      <ChangePasswordForm className="mb2" />
    </>
  );
};

export default MainForms;
