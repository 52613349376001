import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

import { logout } from '../auth-store';

const useAuth = () => {
  const dispatch = useDispatch();
  const { token, isAuth, user } = useSelector((state: RootState) => ({
    token: state.authReducer.token,
    isAuth: Boolean(state.authReducer.token),
    user: state.authReducer.user,
  }));

  return {
    token,
    isAuth,
    user,
    logoutAction: () => dispatch(logout()),
  };
};

export default useAuth;
