import React from 'react';
import classNames from 'classnames';
import './textarea-control.scss';

import { useField } from 'formik';

const TextareaControl = (props: Props) => {
  const [field, meta] = useField<string>(props.name);

  return (
    <section
      className={classNames('textarea-control', props.className, {
        'textarea-control_error': meta.touched && meta.error,
        'textarea-control_disabled': props.disabled,
      })}
    >
      <label className="textarea-control__box">
        <textarea
          {...field}
          className={classNames('textarea-control__input', props.type && `textarea-control__input_${props.type}`)}
          disabled={props.disabled}
          maxLength={props.limit}
        />
        <div
          className={classNames('textarea-control__label', {
            'textarea-control__label_active': Boolean(field.value),
          })}
        >
          {props.label}
        </div>
        <span className="textarea-control__bottom-border" />
        {meta.touched && meta.error && <span className="textarea-control__message">{meta.error}</span>}
      </label>
    </section>
  );
};

type Props = {
  label: string;
  name: string;
  className?: string;
  disabled?: boolean;
  limit?: number;
  type?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export default TextareaControl;
