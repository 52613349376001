import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch } from '../../../../store';
import { resetPassword } from '../../../../store/auth/auth-store';
import { showToast } from '../../../../store/app/app-store';

import PageFrame from '../../../../../shared/components/PageFrame/PageFrame';
import InputControl from '../../../../../shared/components/InputControl/InputControl';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { ResetPasswordDto } from '../../../../store/auth/models/resetPasswordRequestDto';
import { isErrorResponse } from '../../../../../shared/guards/isErrorResponse';
import { PromoImage } from '../../../../../shared/models/promoImage';
import { MIN_PASSWORD_LENGTH } from '../../../../../shared/models/minPasswordLength';

type FormType = {
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormType = {
  password: '',
  passwordConfirmation: '',
};

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Required')
      .min(MIN_PASSWORD_LENGTH, `It should contain at least ${MIN_PASSWORD_LENGTH} symbols`),
    passwordConfirmation: Yup.string().test(
      'is-password-confirmation-match',
      'Password confirmation does not match a Password',
      function (value) {
        // eslint-disable-next-line
        return value === this.parent.password;
      },
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const opt = searchParams.get('opt');
      if (!opt) {
        navigate('/login');
        return Promise.reject();
      }
      return submitResetPasswordForm({ password: values.password, otp: opt });
    },
  });

  const submitResetPasswordForm = async (payload: ResetPasswordDto) => {
    setComponentState('Loading');
    try {
      const response = await dispatch(resetPassword(payload)).unwrap();
      if (isErrorResponse(response)) {
        handleError(response.error);
        return Promise.reject();
      }
      setComponentState('LoadedSuccessful');
      dispatch(showToast({ message: `Your password was successfully reset` }));
      navigate('/login');
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the resetting password process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <div className="container">
      <PageFrame imageUrl={PromoImage.Evening}>
        <section className="layout__sign-up layout__sign-up_average-form">
          <h1 className="layout__sign-up-title">Create New Password</h1>
          <div className="layout__sign-up-text mb4">Input new password and password confirmation</div>
          <FormikProvider value={formik}>
            <form className="form" onSubmit={formik.handleSubmit}>
              <InputControl
                type="password"
                label="Password"
                name="password"
                className="form__row"
                disabled={formik.isSubmitting}
                isAutoFocus
              />
              <InputControl
                type="password"
                label="Password Confirmation"
                name="passwordConfirmation"
                className="form__row"
                isAutocompleteOff
                disabled={formik.isSubmitting}
              />
              <button
                className={classNames('form__submit button button_inline', {
                  button_loading: formik.isSubmitting,
                })}
                type="submit"
                disabled={formik.touched && !formik.isValid}
              >
                Reset Password
              </button>
              <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
                <div className="form__api-message">Error: {errorMessageRef.current}</div>
              </AnimateHeight>
            </form>
          </FormikProvider>
        </section>
      </PageFrame>
    </div>
  );
};

export default ResetPassword;
