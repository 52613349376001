import React, { useState, useEffect } from 'react';
import './nav-switcher.scss';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';

import { NavItem } from './model/navItem';

const NavSwitcher = ({ items, onChange }: Props) => {
  const [activeIds, setActiveIds] = useState<readonly number[]>(
    items.filter((item) => !item.disabled).map((item) => item.id),
  );

  const handleNavSwitch = (e: React.SyntheticEvent, id: number) => {
    e.preventDefault();
    if (activeIds.includes(id)) {
      setActiveIds((prev) => prev.filter((activeId) => activeId !== id));
    } else {
      setActiveIds((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(activeIds);
    }
  }, [activeIds]);

  return (
    <nav
      className={classNames('nav-switcher', {
        'nav-switcher_no-one-active': activeIds.length === 0,
      })}
    >
      {items.map((item) => (
        <section
          className={classNames('nav-switcher__item', {
            'nav-switcher__item_active': activeIds.includes(item.id),
          })}
          key={item.id}
        >
          {!item.disabled ? (
            <>
              <a href="#" className="nav-switcher__link" title="" onClick={(e) => handleNavSwitch(e, item.id)}>
                {item.title}
                <SvgIcon name="back" className="nav-switcher__icon" />
              </a>
              <AnimateHeight duration={350} height={activeIds.includes(item.id) ? 'auto' : 0}>
                <div className="nav-switcher__body">{item.component}</div>
              </AnimateHeight>
            </>
          ) : (
            <div />
          )}
        </section>
      ))}
    </nav>
  );
};

type Props = {
  items: readonly NavItem[];
  onChange?: (ids: readonly number[]) => void;
};

export default NavSwitcher;
