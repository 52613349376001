/* 🤖 this file was generated by svg-to-ts*/
export const svgIcon = {
  back:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M3.5 12l16-9v18l-16-9z"/></svg>',
  edit:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M3 17.252v3.75h3.75l11.06-11.06-3.75-3.75L3 17.252zm17.71-10.21a1 1 0 000-1.41l-2.34-2.34a1 1 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>',
  logo:
    '<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M102.4 138.24h94.72v235.52H102.4zM314.88 138.24h94.72v235.52h-94.72z"/></svg>',
  plus:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M22.462 12.5H1.538v-1h20.924v1z"/><path d="M11.5 1.539h1v20.925h-1V1.539z"/></svg>',
  spinner:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M13 3a1 1 0 00-2 0v4a1 1 0 002 0V3z"/><path opacity=".35" d="M13 17a1 1 0 00-2 0v4a1 1 0 002 0v-4zM21 13a1 1 0 000-2h-4a1 1 0 000 2h4z"/><path d="M7 13a1 1 0 000-2H3a1 1 0 000 2h4zM19.071 6.343a1 1 0 10-1.414-1.414L14.83 7.757a1 1 0 101.414 1.414l2.828-2.828zM9.172 16.243a1 1 0 10-1.414-1.414L4.93 17.657a1 1 0 101.414 1.414l2.828-2.828z"/><path opacity=".35" d="M17.657 19.071a1 1 0 101.414-1.414l-2.828-2.828a1 1 0 10-1.414 1.414l2.828 2.828z"/><path d="M7.757 9.172a1 1 0 101.414-1.414 3771.43 3771.43 0 00-2.828-2.829 1 1 0 10-1.414 1.414l2.828 2.829z"/></svg>',
  user:
    '<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M35.667 12.333c-6.443-6.444-16.89-6.444-23.334 0-6.444 6.443-6.444 16.89 0 23.334 6.443 6.444 16.89 6.444 23.334 0 6.444-6.443 6.444-16.89 0-23.334zm2.121-2.122c7.616 7.616 7.616 19.962 0 27.578-7.615 7.615-19.961 7.615-27.577 0-7.615-7.616-7.615-19.962 0-27.578 7.616-7.615 19.962-7.615 27.578 0z"/><path d="M11.918 38.208C12.892 33.538 18.23 30.5 24 30.5c5.77 0 11.108 3.038 12.082 7.708a1.5 1.5 0 11-2.936.612C32.584 36.13 29.05 33.5 24 33.5c-5.05 0-8.584 2.63-9.146 5.32a1.5 1.5 0 11-2.936-.612z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M27.182 16.818a4.5 4.5 0 10-6.364 6.364 4.5 4.5 0 006.364-6.364zm2.121-2.121a7.5 7.5 0 10-10.606 10.606 7.5 7.5 0 0010.606-10.606z"/></svg>',
  warning:
    '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="svg-icon__body"><path d="M12 2.526c-.777 0-1.554.38-1.975 1.138l-7.74 13.983C1.47 19.122 2.575 21 4.26 21H19.74c1.686 0 2.791-1.878 1.975-3.353L13.975 3.664c-.42-.759-1.198-1.138-1.975-1.138zm-6.258.465a.75.75 0 00-.616 1.175l1 1.5a.75.75 0 101.248-.832l-1-1.5a.75.75 0 00-.632-.343zm12.493 0a.75.75 0 00-.609.343l-1 1.5a.75.75 0 101.248.832l1-1.5a.75.75 0 00-.639-1.175zM12 3.975c.254 0 .508.138.662.416l7.741 13.983c.297.536-.05 1.126-.662 1.126H4.26c-.611 0-.959-.59-.662-1.126l7.74-13.983c.154-.278.408-.416.662-.416zm-8.484 2.02a.75.75 0 00-.388 1.406l1.75 1A.75.75 0 105.622 7.1l-1.75-1a.75.75 0 00-.356-.104zm17.021 0a.75.75 0 00-.41.104l-1.75 1a.75.75 0 00.745 1.302l1.75-1a.75.75 0 00-.335-1.406zM11.988 7.99a.75.75 0 00-.738.761v5a.752.752 0 001.284.537.75.75 0 00.216-.537v-5a.749.749 0 00-.762-.76zM2.25 9.5a.75.75 0 100 1.5h2a.751.751 0 100-1.5h-2zm17.5 0a.75.75 0 100 1.5h2a.751.751 0 100-1.5h-2zM12 16a1 1 0 100 2 1 1 0 000-2z"/></svg>'
};
