import React, { useEffect } from 'react';

import PropertyInfoForm from '../../components/PropertyInfoForm/PropertyForm';
import { trackEvent } from '../../../../../setupAnalytics';

const PropertyInfo = () => {
  useEffect(() => {
    trackEvent('address-screen-viewed-regi');
  }, []);

  return (
    <section className="layout__sign-up layout__sign-up_big-form">
      <div>
        <h1 className="layout__sign-up-title">You’re doing great!</h1>
        <div className="layout__sign-up-text mb4">What is the rental property’s address?</div>
        <PropertyInfoForm className="layout__sign-up-form" />
      </div>
    </section>
  );
};

export default PropertyInfo;
