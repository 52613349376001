import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import './user-menu.scss';
import useAuth from '../../../app/store/auth/hooks/useAuth';

import SvgIcon from '../SvgIcon/SvgIcon';

const AppHeaderUserMenu = ({ className }: Props) => {
  const { isAuth, user, logoutAction } = useAuth();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const navigate = useNavigate();

  const openMenu = () => {
    if (isMenuOpened) {
      closeMenu();
      return;
    }
    setIsMenuOpened(true);
    setTimeout(() => {
      document.addEventListener('click', closeMenu, { once: true });
    }, 0);
  };

  const handleLogout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    logoutAction();
    navigate('/login');
  };

  const closeMenu = () => {
    setIsMenuOpened(false);
  };

  return (
    <div className={classNames('user-menu', className)}>
      {isAuth && (
        <>
          <button
            className={classNames('user-menu__opener', {
              'user-menu__opener_active': isMenuOpened,
            })}
            type="button"
            onClick={() => openMenu()}
          >
            {user?.profileImageUrl !== undefined ? (
              <span style={{ backgroundImage: `url(${user.profileImageUrl})` }} className="user-menu__avatar" />
            ) : (
              <SvgIcon name="user" className="user-menu__default-avatar" />
            )}
          </button>
          <nav
            className={classNames('user-menu__navigation', {
              'user-menu__navigation_shown': isMenuOpened,
            })}
          >
            <ul className="user-menu__item-box">
              <li className="user-menu__item">
                <Link to="/profile" className="user-menu__link" title="Open My Profile">
                  My Profile
                </Link>
              </li>
              <li className="user-menu__item">
                <a
                  href="/logout"
                  className="user-menu__link"
                  title="Logout"
                  onClick={(e) => {
                    handleLogout(e);
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </>
      )}
    </div>
  );
};

type Props = {
  className?: string;
};

export default AppHeaderUserMenu;
