import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import './username-form.scss';
import { useAppDispatch, RootState } from '../../../../store';
import { setUsername } from '../../../../store/auth/auth-store';

import InputControl from '../../../../../shared/components/InputControl/InputControl';

type FormType = {
  firstName: string;
  lastName: string;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

const initialValues: FormType = {
  firstName: '',
  lastName: '',
};

const UsernameForm = ({ className, onUsernameSubmitted }: Props) => {
  const dispatch = useAppDispatch();
  const { registrationForm } = useSelector((state: RootState) => ({
    registrationForm: state.authReducer.registrationForm,
  }));

  useEffect(() => {
    const firstName = registrationForm?.username?.firstName;
    const lastName = registrationForm?.username?.lastName;
    if (firstName === undefined && lastName === undefined) {
      return;
    }

    formik.setValues({
      firstName: firstName ?? formik.values.firstName,
      lastName: lastName ?? formik.values.lastName,
    });
  }, [registrationForm]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      dispatch(setUsername({ ...values }));
      if (onUsernameSubmitted) {
        onUsernameSubmitted();
      }
    },
  });

  return (
    <div className={classNames('username-form', className)}>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl
            label="First Name"
            name="firstName"
            className="form__row"
            disabled={formik.isSubmitting}
            isCapitalize
            isAutoFocus
          />
          <InputControl
            label="Last Name"
            name="lastName"
            className="form__row"
            disabled={formik.isSubmitting}
            isCapitalize
          />
          <button
            className="form__submit button button_inline"
            type="submit"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Next
          </button>
        </form>
      </FormikProvider>
    </div>
  );
};

type Props = {
  className?: string;
  onUsernameSubmitted?: () => void;
};

export default UsernameForm;
