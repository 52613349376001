import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import './page-frame.scss';
import './screen-data.scss';

import LoaderPlaceholder from '../LoaderPlaceholder/LoaderPlaceholder';

import { ScreenData } from './models/screenData';

const PageFrame = ({
  children,
  imageUrl,
  imageText,
  description,
  tvScreenData,
  previewComponent,
  className,
}: Props) => {
  const hasScreenData = tvScreenData !== undefined;
  const previousVideoUrl = useRef<string>();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!tvScreenData?.mediaUrl) {
      return;
    }
    if (
      previousVideoUrl.current !== undefined &&
      previousVideoUrl.current !== tvScreenData.mediaUrl &&
      videoRef.current
    ) {
      videoRef.current.load();
    }
    // eslint-disable-next-line functional/immutable-data
    previousVideoUrl.current = tvScreenData.mediaUrl;
  }, [tvScreenData?.mediaUrl]);

  const backgroundUrl = () => {
    if (hasScreenData) {
      return tvScreenData.backgroundUrlProcessing ? '/images/welcome-placeholder.jpg' : tvScreenData.backgroundUrl;
    }

    return imageUrl;
  };

  return (
    <section className={classNames('page-frame', className)}>
      <div className="page-frame__body">{children}</div>
      <aside className="page-frame__image-box">
        {Boolean(description) && <div className="page-frame__description">{description}</div>}
        {previewComponent === undefined ? (
          <div
            className={classNames('page-frame__image', {
              'page-frame__image_tv-screen': hasScreenData,
            })}
            style={{ backgroundImage: `url(${backgroundUrl()})` }}
          >
            {hasScreenData && (
              <>
                <main className="screen-data page-frame__screen-data">
                  <h5 className="screen-data__title">{tvScreenData.title}</h5>
                  <p className="screen-data__description">{tvScreenData.description}</p>
                  <div className="screen-data__button-box">
                    <button className="screen-data__button" type="button" tabIndex={-1}>
                      GUIDEBOOK
                    </button>
                    <button className="screen-data__button" type="button" tabIndex={-1}>
                      THINGS TO DO
                    </button>
                  </div>
                </main>
                {Boolean(tvScreenData.mediaUrl) && (
                  <div>
                    {!tvScreenData.mediaUrlProcessing ? (
                      <video
                        ref={videoRef}
                        controls
                        disablePictureInPicture
                        disableRemotePlayback
                        className="screen-data__media-preview"
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        muted
                        preload="auto"
                      >
                        <source src={tvScreenData.mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <aside className="screen-data__media-preview screen-data__media-loader">
                        <div className="screen-data__media-message">
                          <LoaderPlaceholder className="screen-data__loader-spinner" type="sm" />
                          <span className="screen-data__loader-text screen-data__loader-text_media">
                            Server is processing video…
                          </span>
                        </div>
                      </aside>
                    )}
                  </div>
                )}
                {tvScreenData.backgroundUrlProcessing && (
                  <div className="screen-data__background-loader">
                    <span className="screen-data__loader-text">Server is processing image…</span>
                    <LoaderPlaceholder className="screen-data__loader-spinner" type="sm" />
                  </div>
                )}
              </>
            )}
            {imageText !== undefined && <main className="screen-data__image-text">{imageText}</main>}
          </div>
        ) : (
          <div className="page-frame__content-box">{previewComponent}</div>
        )}
      </aside>
    </section>
  );
};

type Props = {
  children: React.ReactNode;
  imageUrl: string;
  imageText?: React.ReactNode;
  description?: string | React.ReactNode;
  tvScreenData?: ScreenData;
  previewComponent?: React.ReactNode;
  className?: string;
};

export default PageFrame;
