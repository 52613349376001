import React, { useEffect, useState } from 'react';
import './roku-code.scss';

const INITIAL_SYMBOLS = ['', '', '', '', ''];

const RokuCode = ({ code }: Props) => {
  const [symbols, setSymbols] = useState<readonly string[]>(INITIAL_SYMBOLS);

  useEffect(() => {
    const newSymbols = !code ? [''] : code.split(/(?!$)/);
    if (newSymbols.length > symbols.length) {
      setSymbols(newSymbols);
      return;
    }
    setSymbols(symbols.map((_, i) => (i < newSymbols.length ? newSymbols[i] : '')));
  }, [code]);

  return (
    <section className="roku-code">
      {symbols.map((s, i) => (
        <div key={i} className="roku-code__symbol">
          {s}
        </div>
      ))}
    </section>
  );
};

type Props = {
  code: string;
};

export default RokuCode;
