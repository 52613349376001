import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch } from '../../../../store';
import { getUserHomes } from '../../../../store/home/home-store';
import { useMount } from '../../../../../shared/hooks/useMount';
import useAuth from '../../../../store/auth/hooks/useAuth';

import LoaderPlaceholder from '../../../../../shared/components/LoaderPlaceholder/LoaderPlaceholder';
import ErrorMessage from '../../../../../shared/components/ErrorMessage/ErrorMessage';
import PageFrame from '../../../../../shared/components/PageFrame/PageFrame';
import NavSwitcher from '../../components/NavSwitcher/NavSwitcher';
import WelcomeForm from '../../components/WelcomeForm/WelcomeForm';
import GuestMessageForm from '../../components/GuestMessageForm/GuestMessageForm';
import FaqForm from '../../components/FaqForm/FaqForm';
// import GuestMessagePreview from '../../components/GuestMessagePreview/GuestMessagePreview';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { PromoImage } from '../../../../../shared/models/promoImage';
import { HomeResponseDto } from '../../../../store/home/models/homeResponseDto';
import { NavItem } from '../../components/NavSwitcher/model/navItem';
import { trackEvent } from '../../../../../setupAnalytics';

const HomeDashboard = () => {
  const navigate = useNavigate();
  const [homeDetails, setHomeDetails] = useState<HomeResponseDto>();

  const handleFrameUrlsStateChanged = (state: { isImageUrlProcessing?: boolean; isVideoUrlProcessing?: boolean }) => {
    if (state.isImageUrlProcessing !== undefined) {
      setIsFrameBackgroundUrlProcessing(state.isImageUrlProcessing);
    }
    if (state.isVideoUrlProcessing !== undefined) {
      setIsFrameMediaUrlProcessing(state.isVideoUrlProcessing);
    }
  };

  const switcherConfig: readonly NavItem[] = [
    {
      id: 1,
      title: 'Welcome Screen',
      component: (
        <WelcomeForm
          data={homeDetails?.homeScreen}
          guid={homeDetails?.guid}
          onSubmitted={() => {
            getHomes();
          }}
          onFrameUrlsStateChanged={handleFrameUrlsStateChanged}
        />
      ),
    },
    {
      id: 3,
      title: 'Guidebook',
      component: (
        <FaqForm
          data={homeDetails?.messages}
          guid={homeDetails?.guid}
          onSubmit={() => {
            getHomes();
            trackEvent('faqs-revised');
          }}
        />
      ),
    },
    {
      id: 2,
      title: 'Message Guest',
      component: (
        <GuestMessageForm
          data={homeDetails?.messages}
          guid={homeDetails?.guid}
          onSubmit={() => {
            getHomes();
          }}
        />
      ),
      disabled: true,
    },
  ];

  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const [navSwitcherIds, setNavSwitcherIds] = useState<readonly number[]>(switcherConfig.map((item) => item.id));
  const [isFrameBackgroundUrlProcessing, setIsFrameBackgroundUrlProcessing] = useState<boolean>();
  const [isFrameMediaUrlProcessing, setIsFrameMediaUrlProcessing] = useState<boolean>();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  if (!user) {
    return null;
  }
  useMount(() => {
    getHomes();
  });

  const getHomes = async () => {
    setComponentState(homeDetails === undefined ? 'Loading' : 'Reloading');
    try {
      const homes = await dispatch(getUserHomes()).unwrap();
      const home = homes[homes.length - 1];
      if (homes.length === 0) {
        navigate('/complete-sign-up');
        return;
      }
      if (!user.profileImageUrl) {
        navigate('/complete-sign-up/user-avatar');
        return;
      }

      setHomeDetails(home);
      setComponentState('LoadedSuccessful');
    } catch (error) {
      setComponentState('Error');
    }
  };

  return (
    <div className="container container_lg">
      {componentState === 'Loading' && <LoaderPlaceholder />}
      {componentState === 'Error' && <ErrorMessage>Error</ErrorMessage>}
      {(componentState === 'LoadedSuccessful' || componentState === 'Reloading') && homeDetails !== undefined && (
        <PageFrame
          imageUrl={PromoImage.Evening}
          tvScreenData={{
            title: homeDetails === undefined ? 'Welcome' : `Welcome to ${homeDetails.address.city}`,
            description: homeDetails.homeScreen.caption,
            backgroundUrl: homeDetails.homeScreen.backgroundUrl,
            mediaUrl: homeDetails.homeScreen.mediaUrl,
            backgroundUrlProcessing: isFrameBackgroundUrlProcessing,
            mediaUrlProcessing: isFrameMediaUrlProcessing,
          }}
          description={
            !homeDetails.homeLinks || (homeDetails.homeLinks && homeDetails.homeLinks.length === 0) ? (
              <div>
                Don&apos;t forget to connect the home to your ROKU device, please.
                <br />
                <Link to="/complete-sign-up/link-device" className="layout__white-link layout__white-link_underline">
                  Connect ROKU device
                </Link>
              </div>
            ) : undefined
          }
          className={classNames('layout__element', { layout__element_reloading: componentState === 'Reloading' })}
        >
          <NavSwitcher items={switcherConfig} onChange={(ids) => setNavSwitcherIds(ids)} />
        </PageFrame>
      )}
    </div>
  );
};

export default HomeDashboard;
