import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch, RootState } from '../../../../store';
import { signUpBy } from '../../../../store/auth/auth-store';

import InputControl from '../../../../../shared/components/InputControl/InputControl';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { SignUpRequestDto } from '../../../../store/auth/models/signUpRequestDto';
import { isErrorResponse } from '../../../../../shared/guards/isErrorResponse';
import { MIN_PASSWORD_LENGTH } from '../../../../../shared/models/minPasswordLength';

type FormType = {
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormType = {
  password: '',
  passwordConfirmation: '',
};

const PasswordForm = ({ className, onUserCreated }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Required')
      .min(MIN_PASSWORD_LENGTH, `It should contain at least ${MIN_PASSWORD_LENGTH} symbols`),
    passwordConfirmation: Yup.string().test(
      'is-password-confirmation-match',
      'Password confirmation does not match a Password',
      function (value) {
        // eslint-disable-next-line
        return value === this.parent.password;
      },
    ),
  });

  const { registrationForm } = useSelector((state: RootState) => ({
    registrationForm: state.authReducer.registrationForm,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const firstName = registrationForm?.username?.firstName;
      const lastName = registrationForm?.username?.lastName;
      if (!firstName || !lastName) {
        navigate('/sign-up');
        return undefined;
      }

      const email = registrationForm?.email;

      if (!email) {
        navigate('/sign-up/email');
        return undefined;
      }

      const payload: SignUpRequestDto = {
        name: `${firstName} ${lastName}`,
        email,
        password: values.password,
      };

      return submitSignUpForm(payload);
    },
  });

  const submitSignUpForm = async (payload: SignUpRequestDto) => {
    setComponentState('Loading');
    try {
      const response = await dispatch(signUpBy(payload)).unwrap();
      if (isErrorResponse(response)) {
        handleError(response.error);
        return Promise.reject();
      }
      setComponentState('LoadedSuccessful');
      onUserCreated();
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the sign up process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <div className={classNames('email-form', className)}>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl
            type="password"
            label="Password"
            name="password"
            className="form__row"
            disabled={formik.isSubmitting}
            isAutoFocus
          />
          <InputControl
            type="password"
            label="Password Confirmation"
            name="passwordConfirmation"
            className="form__row"
            isAutocompleteOff
            disabled={formik.isSubmitting}
          />
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: formik.isSubmitting,
            })}
            type="submit"
            disabled={formik.touched && !formik.isValid}
          >
            Next
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">Error: {errorMessageRef.current}</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </div>
  );
};

export default PasswordForm;

type Props = {
  className?: string;
  onUserCreated: () => void;
};
