import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { DeviceDto, HomeResponseDto } from './models/homeResponseDto';
import { CreateHomeRequestDto } from './models/createHomeRequestDto';
import { UpdateGreetingRequest } from './models/updateGreetingRequest';
import { LinkDeviceRequestDto } from './models/linkDeviceRequestDto';
import { UpdateScreenRequest } from './models/updateScreenRequest';
import { AddressResponseDto } from './models/addressResponseDto';
import { MediaLinkRequestDto } from './models/mediaLinkRequestDto';
import { MediaLinkResponseDto } from './models/mediaLinkResponseDto';
import { ResourceUploadingType } from './models/resourceUploadingType';

type HomeState = {
  readonly userHomes: readonly HomeResponseDto[];
  readonly activeHomeGuid: string;
  readonly activeHomeAddress: AddressResponseDto | undefined;
  readonly activeHomeRokuDevices: readonly DeviceDto[] | null;
  readonly imageUploadingProgress: number;
  readonly videoUploadingProgress: number;
  readonly isUIBlockedTooLong: boolean;
};

const initialState: HomeState = {
  userHomes: [],
  activeHomeGuid: '',
  activeHomeAddress: undefined,
  activeHomeRokuDevices: null,
  imageUploadingProgress: 0,
  videoUploadingProgress: 0,
  isUIBlockedTooLong: false,
};

export const getUserHomes = createAsyncThunk('getUserHomes', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<readonly HomeResponseDto[]>(`/homes`);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const createHome = createAsyncThunk('createHome', async (payload: CreateHomeRequestDto, { rejectWithValue }) => {
  try {
    const response = await axios.post<HomeResponseDto>(`/homes/create`, payload);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const updateHomeAddress = createAsyncThunk(
  'updateHomeAddress',
  async (payload: CreateHomeRequestDto, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const homeGuid = state.homeReducer.activeHomeGuid;
      const response = await axios.patch<HomeResponseDto>(`/homes/${homeGuid}`, {
        address: payload.address,
      });
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateHomeGreetings = createAsyncThunk(
  'updateHomeGreetings',
  async (payload: UpdateGreetingRequest, { rejectWithValue }) => {
    try {
      const response = await axios.patch<HomeResponseDto>(`/homes/${payload.homeGiud}`, {
        messages: payload.messages,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateHomeScreen = createAsyncThunk(
  'updateHomeScreen',
  async (payload: UpdateScreenRequest, { rejectWithValue }) => {
    try {
      const response = await axios.patch<any>(`/homes/${payload.homeGiud}`, {
        homeScreen: {
          caption: payload.caption,
          backgroundUrl: payload.backgroundUrl,
          mediaUrl: payload.mediaUrl,
        },
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const linkDeviceToHome = createAsyncThunk(
  'linkDeviceToHome',
  async ({ rokuPin }: { readonly rokuPin: string }, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const homeGuid = state.homeReducer.activeHomeGuid;
      if (!homeGuid) {
        return thunkAPI.rejectWithValue('Unknown home guid');
      }
      const request: LinkDeviceRequestDto = { rokuPin, homeGuid };
      const response = await axios.post<{ readonly success: boolean }>(`/homes/link`, request);
      if (response.data.success) {
        return response.data;
      }
      return thunkAPI.rejectWithValue('Unknown error');
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const unlinkUserHomeDevice = createAsyncThunk(
  'unlinkUserHomeDevice',
  async (payload: { readonly deviceId: string }, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const homeGuid = state.homeReducer.activeHomeGuid;
      const response = await axios.delete<any>(`/homes/link/${homeGuid}/${payload.deviceId}`);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getMediaUploadLink = createAsyncThunk(
  'getMediaUploadLink',
  async (payload: MediaLinkRequestDto, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const homeGuid = state.homeReducer.activeHomeGuid;

      const response = await axios.post<MediaLinkResponseDto>('/media/link', {
        ...payload,
        homeUuid: homeGuid,
      });

      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const uploadMediaS3 = createAsyncThunk(
  'uploadMediaS3',
  async (
    payload: { readonly link: string; readonly data: any; readonly resourceType?: ResourceUploadingType },
    thunkApi,
  ) => {
    try {
      return await axios.put(payload.link, payload.data, {
        headers: {
          'Content-Type': payload.data.type,
          'x-bypass-auth': 'true',
        },
        onUploadProgress: ({ progress }) => {
          if (progress === undefined) {
            return;
          }
          thunkApi.dispatch(setUploadingProgress({ progress, resourceType: payload.resourceType }));
        },
      });
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.response.data);
    }
  },
);

const homeReducer = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setUploadingProgress: (
      state,
      action: PayloadAction<{ readonly progress: number; resourceType?: ResourceUploadingType }>,
    ) => {
      if (action.payload.resourceType === 'video') {
        return {
          ...state,
          videoUploadingProgress: action.payload.progress,
        };
      }
      return {
        ...state,
        imageUploadingProgress: action.payload.progress,
      };
    },
    clearUploadingProgress: (state) => ({
      ...state,
      imageUploadingProgress: 0,
      videoUploadingProgress: 0,
    }),
    showUnblockUIToaster: (state) => ({
      ...state,
      isUIBlockedTooLong: true,
    }),
    hideUnblockUIToaster: (state) => ({
      ...state,
      isUIBlockedTooLong: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(createHome.fulfilled, (state, action) => {
      const response = action.payload;

      if (response && response.guid) {
        const activeHomeGuid = response.guid;
        return {
          ...state,
          activeHomeGuid,
          activeHomeAddress: response.address,
          activeHomeRokuDevices: response.homeLinks ? [...response.homeLinks] : null,
        };
      }
      return {
        ...state,
      };
    });

    builder.addCase(updateHomeAddress.fulfilled, (state, action) => {
      const response = action.payload;

      if (response && response.guid) {
        const activeHomeGuid = response.guid;
        return {
          ...state,
          activeHomeGuid,
          activeHomeAddress: response.address,
        };
      }
      return {
        ...state,
      };
    });

    builder.addCase(getUserHomes.fulfilled, (state, action) => {
      const response = action.payload;
      if (response && response.length > 0) {
        const lastHome = response[response.length - 1];
        const activeHomeGuid = lastHome.guid;
        const activeHomeAddress = lastHome.address;
        const activeHomeRokuDevices = lastHome.homeLinks ? [...lastHome.homeLinks] : null;
        return {
          ...state,
          activeHomeGuid,
          activeHomeAddress,
          activeHomeRokuDevices,
        };
      }
      return {
        ...state,
      };
    });
  },
});

export const { setUploadingProgress, clearUploadingProgress, showUnblockUIToaster, hideUnblockUIToaster } =
  homeReducer.actions;

export default homeReducer.reducer;
