import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../shared/components/PrivateRoute/PrivateRoute';
import SignIn from './features/auth/pages/SignIn/SignIn';
import SignUp from './features/auth/pages/SignUp/SignUp';
import PasswordRecovery from './features/auth/pages/PasswordRecovery/PasswordRecovery';
import HomeDashboard from './features/home/pages/HomeDashboard/HomeDashboard';
import UserProfile from './features/user-profile/pages/UserProfile/UserProfile';
import NotFound from './features/not-found/pages/NotFound/NotFound';
import EmailVerifier from './features/auth/pages/EmailVerifier/EmailVerifier';
import CompleteSignUp from './features/auth/pages/CompleteSignUp/CompleteSignUp';
import ResetPassword from './features/auth/pages/ResetPassword/ResetPassword';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up/*" element={<SignUp />} />
      <Route
        path="/complete-sign-up/*"
        element={
          <PrivateRoute>
            <CompleteSignUp />
          </PrivateRoute>
        }
      />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<EmailVerifier />} />
      <Route
        index
        element={
          <PrivateRoute>
            <HomeDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/*"
        element={
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
