import React from 'react';
import { Link } from 'react-router-dom';
import UsernameForm from '../../components/UsernameForm/UsernameForm';

const SignUpStep1 = ({ onUsernameSubmitted }: Props) => {
  const handleUsernameSubmitted = () => {
    onUsernameSubmitted();
  };

  return (
    <section className="layout__sign-up">
      <h1 className="layout__sign-up-title">Welcome!</h1>
      <div className="layout__sign-up-text">Before we get started, what&apos;s your name?</div>
      <UsernameForm className="layout__sign-up-form" onUsernameSubmitted={handleUsernameSubmitted} />
      <hr className="layout__hr layout__sign-up-hr" />
      <div className="layout__description">
        Already have an account?{' '}
        <Link to="/login" className="layout__link layout__link_strong">
          Sign in here
        </Link>
      </div>
    </section>
  );
};

type Props = {
  onUsernameSubmitted: () => void;
};

export default SignUpStep1;
