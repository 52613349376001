import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './user-profile.scss';
import useAuth from '../../../../store/auth/hooks/useAuth';

import MainForms from '../MainForms/MainForms';
import Properties from '../Properties/Properties';
import ComingSoon from '../ComingSoon/ComingSoon';
import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';

const UserProfile = () => {
  const { pathname } = useLocation();
  const { logoutAction } = useAuth();

  const baseUrl = '/profile';

  const navPaths = [
    {
      name: 'My Profile',
      url: '',
      component: <MainForms />,
    },
    {
      name: 'Property Settings',
      url: '/properties',
      component: <Properties />,
    },
    {
      name: 'Help',
      url: '/help',
      component: <ComingSoon />,
    },
  ];

  const handleLogout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    logoutAction();
  };

  return (
    <div className="container">
      <section className="user-profile">
        <nav className="user-profile__nav">
          <ul className="user-profile__list">
            {navPaths.map((navItem) => (
              <li className="user-profile__item" key={navItem.name}>
                <Link
                  to={`${baseUrl}${navItem.url}`}
                  className={classNames('user-profile__link', {
                    'user-profile__link_active': pathname === `${baseUrl}${navItem.url}`,
                  })}
                  title=""
                >
                  {navItem.name}
                  <SvgIcon name="back" className="user-profile__link-icon" />
                </Link>
              </li>
            ))}
            <li className="user-profile__item" key="logout">
              <a href="#" className="user-profile__link" title="Logout" onClick={(e) => handleLogout(e)}>
                Logout
                <SvgIcon name="back" className="user-profile__link-icon" />
              </a>
            </li>
          </ul>
        </nav>
        <main className="user-profile__body">
          <Routes>
            {navPaths.map((navItem) => (
              <Route path={navItem.url} element={navItem.component} key={navItem.name} />
            ))}
            <Route path="*" element={<Navigate to="" />} />
          </Routes>
        </main>
      </section>
    </div>
  );
};

export default UserProfile;
