import React from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import PageFrame from '../../../../../shared/components/PageFrame/PageFrame';
import SignUpStep1 from '../SignUpStep1/SignUpStep1';
import SignUpStep2 from '../SignUpStep2/SignUpStep2';
import SignUpStep3 from '../SignUpStep3/SignUpStep3';
import SignUpStep4 from '../SignUpStep4/SignUpStep4';
import SignUpFinish from '../SignUpFinish/SignUpFinish';

import { PromoImage } from '../../../../../shared/models/promoImage';
import { trackEvent } from '../../../../../setupAnalytics';

const SignUp = () => {
  const navigate = useNavigate();

  const moveToWelcomeSection = () => {
    trackEvent('name-added-regi');
    navigate('/sign-up/welcome');
  };

  const moveToPasswordSection = () => {
    trackEvent('email-added-regi');
    navigate('/sign-up/password');
  };

  const moveToAwaitingVerification = () => {
    trackEvent('pw-added-regi');
    navigate('/sign-up/finish');
  };

  return (
    <div className="container">
      <PageFrame imageUrl={PromoImage.Evening}>
        <Routes>
          <Route index element={<SignUpStep1 onUsernameSubmitted={moveToWelcomeSection} />} />
          <Route path="welcome" element={<SignUpStep2 />} />
          <Route path="email" element={<SignUpStep3 onEmailSubmitted={moveToPasswordSection} />} />
          <Route path="password" element={<SignUpStep4 onUserCreated={moveToAwaitingVerification} />} />
          <Route path="finish" element={<SignUpFinish />} />
          <Route path="*" element={<Navigate to="" />} />
        </Routes>
      </PageFrame>
    </div>
  );
};

export default SignUp;
