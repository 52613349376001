import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import './user-avatar.scss';
import { useAppDispatch } from '../../../../store';
import useAuth from '../../../../store/auth/hooks/useAuth';
import { updateUserProfile, setNewUserAvatar } from '../../../../store/auth/auth-store';
import { getMediaUploadLink, uploadMediaS3 } from '../../../../store/home/home-store';
import { showToast } from '../../../../store/app/app-store';
import { waitResourceReadiness } from '../../../../../shared/utils/waitResourceReadiness';

import InputControl from '../../../../../shared/components/InputControl/InputControl';
import SectionHeader from '../../../../../shared/components/SectionHeader/SectionHeader';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';

type FormType = {
  name: string;
  profileUrl: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  profileUrl: Yup.string().required('Required'),
});

const DELAY_WHILE_CASH_CLEAN = 3000;

const UserDetailForm = ({ className }: Props) => {
  const { user } = useAuth();
  if (!user) {
    return null;
  }
  const dispatch = useAppDispatch();
  const [uploadingImageState, setUploadingImageState] = useState<ApiInteractionState>();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorImageUploadingMessageRef = useRef<string>();

  const initialValues: FormType = {
    name: `${user.firstName} ${user.lastName}`,
    profileUrl: user.profileImageUrl,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      submitForm(values);
    },
  });

  const handleChangeAvatar = (event: any) => {
    if (user === undefined) {
      return;
    }
    if (event.target.files.length <= 0) {
      return;
    }
    const formData = new FormData();
    const file: File = event.target.files[0];
    formData.append('profile', file);
    dispatch(getMediaUploadLink({ fileType: 'profile' }))
      .unwrap()
      .then((response) => {
        uploadAvatar(formData, response.link, response.resultFilename);
      });
  };

  const uploadAvatar = async (formData: FormData, uploadUrl: string, userProfileUrl: string) => {
    setUploadingImageState('Loading');
    try {
      await dispatch(uploadMediaS3({ link: uploadUrl, data: formData.get('profile') })).unwrap();
      const isImageReady = await waitResourceReadiness(userProfileUrl);
      formik.setFieldValue('profileUrl', userProfileUrl);
      setUploadingImageState('LoadedSuccessful');
      dispatch(setNewUserAvatar({ url: userProfileUrl }));
      dispatch(
        showToast({
          message: `Your profile image was changed.${
            isImageReady ? '' : ' Server is processing you image. You can refresh browser page to see updated image'
          }`,
        }),
      );
    } catch (error: any) {
      console.warn(error);
      // eslint-disable-next-line functional/immutable-data
      errorImageUploadingMessageRef.current = error?.message ?? 'Unknown image uploading error';
      setUploadingImageState('Error');
    }
  };

  const submitForm = async (values: FormType) => {
    setComponentState('Loading');
    try {
      await dispatch(updateUserProfile({ name: values.name, metadata: { profile_url: values.profileUrl } })).unwrap();
      setComponentState('LoadedSuccessful');
      dispatch(showToast({ message: 'Your Name was successfully updated' }));
    } catch (error: any) {
      setComponentState('Error');
    }
  };

  return (
    <div className={classNames(className)}>
      <SectionHeader className="mb2">Profile Image</SectionHeader>
      <div className="layout__body-centered mb4">
        <label
          className="user-avatar__preview mb2 "
          style={
            formik.values.profileUrl
              ? {
                  backgroundImage: `url(${formik.values.profileUrl})`,
                }
              : undefined
          }
          title={user?.profileImageUrl ? 'Change Profile Image' : 'Upload Profile Image'}
        >
          <input type="file" onChange={handleChangeAvatar} className="visually-hidden" />
        </label>
        <label
          className={classNames('button button_inline', {
            button_loading: uploadingImageState === 'Loading',
          })}
        >
          <input type="file" onChange={handleChangeAvatar} className="visually-hidden" />
          UPLOAD
        </label>
        <AnimateHeight
          duration={350}
          height={
            uploadingImageState === 'Error' || (formik.touched.profileUrl && formik.errors.profileUrl) ? 'auto' : 0
          }
        >
          {uploadingImageState === 'Error' && (
            <div className="form__api-message">Error: {errorImageUploadingMessageRef.current}</div>
          )}
          <div className="form__api-message">{formik.errors.profileUrl}</div>
        </AnimateHeight>
      </div>
      <SectionHeader className="mb2">CONTACT INFO</SectionHeader>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl label="Your Name" name="name" className="form__row" disabled={formik.isSubmitting} />
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Update
          </button>
          <AnimateHeight duration={350} height={componentState === 'LoadedSuccessful' ? 'auto' : 0}>
            <div className="form__api-message form__api-message_success">Successfully Updated</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </div>
  );
};

type Props = {
  className?: string;
};

export default UserDetailForm;
