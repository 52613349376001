import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import './toggle-control.scss';

const ToggleControl = (props: Props) => {
  const [field, meta] = useField<string>(props.name);

  return (
    <label
      className={classNames(
        'toggle-control',
        {
          'toggle-control_error': meta.touched && meta.error,
          'toggle-control_disabled': props.disabled,
        },
        props.className,
      )}
    >
      <input className="toggle-control__input" type="checkbox" {...field} checked={Boolean(field.value)} />
      <div className="toggle-control__switcher" />
    </label>
  );
};

type Props = {
  name: string;
  disabled?: boolean;
  className?: string;
};

export default ToggleControl;
