/* eslint-disable functional/immutable-data */
import axios from 'axios';
import { store } from '../../app/store';
import { logout } from '../../app/store/auth/auth-store';

const AUTH_TOKEN_EXPIRED_ERROR_CODE = 403;

const httpConfig = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.responseType = 'json';

  axios.interceptors.request.use((request) => {
    if (request === undefined || request.headers === undefined) {
      return request;
    }
    request.headers['x-alcove-api-token'] = 'webToken';
    if (!request.headers['Content-Type']) {
      request.headers['Content-Type'] = 'application/json';
    }

    const { token } = store.getState().authReducer;

    if (!('x-bypass-auth' in request.headers) && token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { token, tokenExpires } = store.getState().authReducer;
      if (Boolean(token) && tokenExpires && new Date(tokenExpires).getTime() <= new Date().getTime()) {
        store.dispatch(logout());
      }
      return Promise.reject(error);
    },
  );
};

export default httpConfig;
