import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import RokuCode from '../../components/RokuCode/RokuCode';
import PageFrame from '../../../../../shared/components/PageFrame/PageFrame';
import PropertyInfo from '../PropertyInfo/PropertyInfo';
import UserAvatar from '../UserAvatar/UserAvatar';
import LinkDevice from '../LinkDevice/LinkDevice';
import SighUpFinished from '../SighUpFinished/SighUpFinished';

import { PromoImage } from '../../../../../shared/models/promoImage';

const CompleteSignUp = () => {
  const location = useLocation();
  const [{ promoImage, promoText }, setPromoImage] = useState<PromoType>({
    promoImage: PromoImage.Day,
    promoText: '',
  });
  const [rokuCode, setRokuCode] = useState<string>();

  useEffect(() => {
    setPromoImage(getPromo(location.pathname));
  }, [location.pathname]);

  const getPromo = (path: string): PromoType => {
    const map: { [key: string]: PromoType } = {
      '/complete-sign-up': {
        promoImage: PromoImage.Day,
        promoText: 'Just add one property for now. If you have more you can add them later.',
      },
      '/complete-sign-up/user-avatar': {
        promoImage: PromoImage.Kitchen,
        promoText: '',
      },
      '/complete-sign-up/link-device': {
        promoImage: PromoImage.Bedroom,
        promoText: (
          <div>
            Learn how to download the Home Guide ROKU application —{' '}
            <a
              href="https://www.youtube.com/watch?v=PtAl-qZ-5Qg"
              target="_blank"
              rel="noreferrer nofollow"
              title="Home Guide Roku"
              className="layout__white-link"
            >
              Click here
            </a>
          </div>
        ),
      },
      '/complete-sign-up/finished': {
        promoImage: PromoImage.LivingRoom,
        promoText: '',
      },
    };

    if (Object.hasOwn(map, path)) {
      return map[path];
    }

    return {
      promoImage: PromoImage.Day,
      promoText: '',
    };
  };

  const handleChangeRokuCode = (code: string) => {
    setRokuCode(code);
  };

  return (
    <div className="container">
      <PageFrame
        imageUrl={promoImage}
        imageText={
          location.pathname === '/complete-sign-up/link-device' ? (
            <>
              <div className="layout__roku-code">
                Link Code: <RokuCode code={rokuCode ?? ''} />
              </div>
              <div className="layout__roku-description">
                Type the code into the Host Dashboard. The screen will automatically refresh.
              </div>
            </>
          ) : undefined
        }
        description={promoText}
      >
        <Routes>
          <Route index element={<PropertyInfo />} />
          <Route path="user-avatar" element={<UserAvatar />} />
          <Route path="link-device" element={<LinkDevice onChangeCode={handleChangeRokuCode} />} />
          <Route path="finished" element={<SighUpFinished />} />
          <Route path="*" element={<Navigate to="" />} />
        </Routes>
      </PageFrame>
    </div>
  );
};

type PromoType = {
  promoImage: PromoImage;
  promoText: string | React.ReactNode;
};

export default CompleteSignUp;
