import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { useDispatch } from 'react-redux';
import appReducer from './store/app/app-store';
import authReducer from './store/auth/auth-store';
import homeReducer from './store/home/home-store';

const authPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['authError'],
};

const rootReducer = () =>
  combineReducers({
    appReducer,
    authReducer: persistReducer(authPersistConfig, authReducer),
    homeReducer,
  });

const persistedReducer = rootReducer();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
