import React, { useEffect, useState } from 'react';
import './app-toasts.scss';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../../app/store';
import { closeToastById } from '../../../app/store/app/app-store';
import { AppToast } from '../../../app/store/app/models/appToast';

import SvgIcon from '../SvgIcon/SvgIcon';

const AUTO_CLOSE_AFTER = 5000;
const NEVER_CLOSE_TIME = 2592000000;
const ANIMATION_SPEED = 250;

const AppToasts = () => {
  const [componentToasts, setComponentToasts] = useState<readonly ComponentToast[]>([]);
  const dispatch = useAppDispatch();
  const { toasts } = useSelector((state: RootState) => ({
    toasts: state.appReducer.toasts,
  }));

  useEffect(() => {
    if (componentToasts.length < toasts.length) {
      // New Toast
      const operationalToast = toasts.filter((t) => !componentToasts.some((ct) => ct.data.id === t.id));
      if (operationalToast.length === 0) {
        return;
      }
      const newComponentToasts: readonly ComponentToast[] = [
        ...componentToasts,
        ...operationalToast.map((ot) => ({
          data: { ...ot },
          state: 'WillInsert' as 'WillDelete' | 'WillInsert' | 'Default',
          expire: new Date().getTime() + (ot.noAutoClose ? NEVER_CLOSE_TIME : AUTO_CLOSE_AFTER),
        })),
      ];
      setComponentToasts(newComponentToasts);
      return;
    }
    if (componentToasts.length > toasts.length) {
      // Delete Toast
      const operationalToast = componentToasts.filter((ct) => !toasts.some((t) => ct.data.id === t.id));
      if (operationalToast.length === 0) {
        return;
      }
      const newComponentToasts: readonly ComponentToast[] = componentToasts.map((ct) => ({
        ...ct,
        state: operationalToast.some((ot) => ot.data.id === ct.data.id) ? 'WillDelete' : 'Default',
      }));
      setComponentToasts(newComponentToasts);
    }
  }, [toasts]);

  useEffect(() => {
    if (componentToasts.length === 0) {
      return;
    }
    if (componentToasts.some((ct) => ct.state === 'WillInsert')) {
      setTimeout(() => {
        setComponentToasts(
          componentToasts.map((ct) => ({
            ...ct,
            state: 'Default',
          })),
        );
      }, ANIMATION_SPEED);
    }
    if (componentToasts.some((ct) => ct.state === 'WillDelete')) {
      setTimeout(() => {
        setComponentToasts(componentToasts.filter((ct) => ct.state !== 'WillDelete'));
      }, ANIMATION_SPEED);
    }
  }, [componentToasts]);

  useEffect(() => {
    if (componentToasts.length === 0) {
      return undefined;
    }
    const _timerId = setInterval(() => {
      checkExpiresToasts();
      // eslint-disable-next-line no-magic-numbers
    }, 1000);
    return () => {
      clearInterval(_timerId);
    };
  }, [componentToasts]);

  const checkExpiresToasts = () => {
    componentToasts.forEach((ct) => {
      if (new Date().getTime() > ct.expire) {
        handleClose(ct.data.id);
      }
    });
  };

  const handleClose = (id: number) => {
    dispatch(closeToastById({ id }));
  };

  return (
    <section className="app-toasts">
      {componentToasts.map((toast) => (
        <div key={toast.data.id} className="app-toasts__message-box">
          <AnimateHeight duration={ANIMATION_SPEED} height={toast.state === 'Default' ? 'auto' : 0}>
            <aside
              className={classNames('app-toasts__message', {
                'app-toasts__message_error': toast.data.type === 'Error',
                'app-toasts__message_neutral': toast.data.type === 'Neutral',
                'app-toasts__message_shown': toast.state === 'Default',
                'app-toasts__message_has-button': toast.data.button !== undefined,
              })}
            >
              <span className="app-toasts__text">{toast.data.message}</span>
              <button
                type="button"
                className="app-toasts__closer"
                onClick={() => {
                  handleClose(toast.data.id);
                }}
              >
                <SvgIcon name="plus" className="app-toasts__close-icon" />
              </button>
              {toast.data.button !== undefined && (
                <button
                  type="button"
                  className="app-toasts__action-button"
                  onClick={() => {
                    if (!toast.data.button) {
                      return;
                    }
                    if (toast.data.button.closeAfterCallback) {
                      handleClose(toast.data.id);
                    }
                    toast.data.button.callback();
                  }}
                >
                  {toast.data.button.title}
                </button>
              )}
            </aside>
          </AnimateHeight>
        </div>
      ))}
    </section>
  );
};

type ComponentToast = {
  data: AppToast;
  state: 'WillDelete' | 'WillInsert' | 'Default';
  expire: number;
};

export default AppToasts;
