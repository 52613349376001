import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch, RootState } from '../../../../store';
import { linkDeviceToHome, getUserHomes } from '../../../../store/home/home-store';
import { useMount } from '../../../../../shared/hooks/useMount';

import InputControl from '../../../../../shared/components/InputControl/InputControl';
import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { trackEvent } from '../../../../../setupAnalytics';

type FormType = {
  rokuPin: string;
};

const validationSchema = Yup.object().shape({
  rokuPin: Yup.string().required('Required'),
});

const initialValues: FormType = {
  rokuPin: '',
};

const LinkDevice = ({ onChangeCode }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();
  const { activeHomeGuid } = useSelector((state: RootState) => ({
    activeHomeGuid: state.homeReducer.activeHomeGuid,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      trackEvent('link-roku-screen-confirmed-regi');
      return linkRokuDevice(values.rokuPin);
    },
  });

  useMount(() => {
    trackEvent('link-roku-screen-viewed-regi');
    if (!activeHomeGuid) {
      getHomes();
    }
  });

  const getHomes = async () => {
    setComponentState('Loading');
    try {
      await dispatch(getUserHomes()).unwrap();
      setComponentState('LoadedSuccessful');
    } catch (error) {
      setComponentState('Error');
    }
  };

  useEffect(() => {
    onChangeCode(formik.values.rokuPin);
  }, [formik.values.rokuPin]);

  const linkRokuDevice = async (rokuPin: string) => {
    setComponentState('Loading');
    try {
      await dispatch(linkDeviceToHome({ rokuPin })).unwrap();
      setComponentState('LoadedSuccessful');
      navigate('/complete-sign-up/finished');
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the linking Roku device process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <section className="layout__sign-up layout__sign-up_small-form">
      <h1 className="layout__sign-up-title">Last step! Link your host account to your ROKU device.</h1>
      <div className="user-avatar__message">
        To complete this step, you need to download the Home Guide channel to your ROKU application. For directions,{' '}
        <a
          href="https://www.alcoveapp.co/docs/2019/3/11/zrmanja-falls-croatia-f3exm-mkbl2-jpke9"
          target="_blank"
          rel="noreferrer nofollow"
          title="Home Guide Roku"
        >
          click here.
        </a>
      </div>
      <div className="layout__sign-up-text mb4">Enter the link code below</div>
      <div className={classNames('link-device-form')}>
        <FormikProvider value={formik}>
          <form className="form" onSubmit={formik.handleSubmit}>
            <InputControl
              label="Link Code"
              name="rokuPin"
              className="form__row"
              disabled={formik.isSubmitting}
              maxlength={9}
              isAutoFocus
              isUpperCase
              onFocus={() => {
                trackEvent('link-roku-screen-clicked-regi');
              }}
            />
            <button
              className="button mt2"
              type="submit"
              disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
            >
              Confirm link code
            </button>
            <Link
              to="/complete-sign-up/finished"
              className="button-link mt2"
              onClick={() => {
                trackEvent('link-roku-screen-skipped-regi');
              }}
            >
              I&apos;ll do this later
            </Link>
            <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
              <div className="form__api-message">Error: {errorMessageRef.current}</div>
            </AnimateHeight>
          </form>
        </FormikProvider>
      </div>
    </section>
  );
};

type Props = {
  onChangeCode: (code: string) => void;
};

export default LinkDevice;
