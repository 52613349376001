import React from 'react';
import { Link, To } from 'react-router-dom';
import classNames from 'classnames';
import './back-button.scss';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';

const BackButton = ({ to, onClick, children, className }: Props) => {
  const handleBackClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  const buttonContent = () => (
    <>
      <SvgIcon name="back" className="back-button__icon" />
      <span className="back-button__text">{children !== undefined ? children : 'Back'}</span>
    </>
  );

  const cssClass = classNames('back-button', className);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {to !== undefined ? (
        <Link to={to} className={cssClass} title="Go Back">
          {buttonContent()}
        </Link>
      ) : (
        <a href="#back" className={cssClass} title="Go Back" onClick={handleBackClick}>
          {buttonContent()}
        </a>
      )}
    </>
  );
};

type Props = {
  to?: To;
  onClick?: () => void;
  children?: string;
  className?: string;
};

export default BackButton;
