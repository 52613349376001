import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch } from '../../../../store';
import { changeUserPassword } from '../../../../store/auth/auth-store';
import { showToast } from '../../../../store/app/app-store';

import InputControl from '../../../../../shared/components/InputControl/InputControl';
import SectionHeader from '../../../../../shared/components/SectionHeader/SectionHeader';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { MIN_PASSWORD_LENGTH } from '../../../../../shared/models/minPasswordLength';
import { isErrorResponse } from '../../../../../shared/guards/isErrorResponse';

type FormType = {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
};

const initialValues: FormType = {
  oldPassword: '',
  newPassword: '',
  passwordConfirmation: '',
};

const ChangePasswordForm = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .required('Required')
      .min(MIN_PASSWORD_LENGTH, `It should contain at least ${MIN_PASSWORD_LENGTH} symbols`),
    passwordConfirmation: Yup.string().test(
      'is-password-confirmation-match',
      'Password confirmation does not match a Password',
      function (value) {
        // eslint-disable-next-line
        return value === this.parent.newPassword;
      },
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      changePassword(values);
    },
  });

  const changePassword = async (values: FormType) => {
    setComponentState('Loading');
    try {
      const response = await dispatch(
        changeUserPassword({ oldPassword: values.oldPassword, password: values.newPassword }),
      ).unwrap();
      if (isErrorResponse(response)) {
        setComponentState('Error');
        // eslint-disable-next-line functional/immutable-data
        errorMessageRef.current = response.error;
        return Promise.reject();
      }
      setComponentState('LoadedSuccessful');
      dispatch(showToast({ message: 'Your password was successfully updated' }));
      return Promise.resolve();
    } catch (error: any) {
      setComponentState('Error');
      return Promise.reject();
    }
  };

  return (
    <div className={classNames(className)}>
      <SectionHeader className="mb2">Change Your Password</SectionHeader>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl
            type="password"
            label="Old Password"
            name="oldPassword"
            className="form__row"
            disabled={formik.isSubmitting}
          />
          <InputControl
            type="password"
            label="New Password"
            name="newPassword"
            className="form__row"
            disabled={formik.isSubmitting}
          />
          <InputControl
            type="password"
            label="Password Confirmation"
            name="passwordConfirmation"
            className="form__row"
            disabled={formik.isSubmitting}
          />
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Change Password
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">{errorMessageRef.current}</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </div>
  );
};

type Props = {
  readonly className?: string;
};

export default ChangePasswordForm;
