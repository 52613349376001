import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { LoginRequestDto } from './models/loginRequestDto';
import { LoginResponseDto } from './models/loginResponseDto';
import { RegistrationForm, UsernameType } from './models/registrationForm';
import { SignUpRequestDto } from './models/signUpRequestDto';
import { SimpleResponseDto } from './models/simpleResponseDto';
import { ErrorResponseDto } from '../../../shared/models/errorResponseDto';
import { User } from './models/user';
import { ResetPasswordDto } from './models/resetPasswordRequestDto';
import { UpdateUserProfileRequestDto } from './models/updateUserProfileRequestDto';
import { UpdateUserProfileResponseDto } from './models/updateUserProfileResponseDto';

import { mapUser, getUserName } from './utils/mapUser';
import { isLoginErrorResponse } from '../../../shared/guards/isLoginErrorResponse';

type AuthState = {
  registrationForm: RegistrationForm | undefined;
  token: string | undefined;
  tokenExpires: Date | undefined;
  user: User | undefined;
};

const initialState: AuthState = {
  registrationForm: undefined,
  token: undefined,
  tokenExpires: undefined,
  user: undefined,
};

export const loginBy = createAsyncThunk(
  'loginBy',
  async ({ email, password }: LoginRequestDto, { rejectWithValue }) => {
    try {
      const response = await axios.post<LoginResponseDto | ErrorResponseDto>(`/auth/login`, { email, password });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const signUpBy = createAsyncThunk('signUpBy', async (payload: SignUpRequestDto, { rejectWithValue }) => {
  try {
    const response = await axios.post<SimpleResponseDto | ErrorResponseDto>(`/auth/signup`, payload);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const resendVerificationLink = createAsyncThunk('resendVerificationLink', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<SimpleResponseDto | ErrorResponseDto>(`/auth/verify/resend`);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});

export const verificationEmail = createAsyncThunk(
  'verificationEmail',
  async (payload: { otp: string }, { rejectWithValue }) => {
    try {
      const response = await axios.put<SimpleResponseDto | ErrorResponseDto>(`/auth/verify`, { otp: payload.otp });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const recoverUserPassword = createAsyncThunk(
  'recoverUserPassword',
  async (payload: { email: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post<SimpleResponseDto | ErrorResponseDto>(`/auth/password/forgot`, payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (payload: ResetPasswordDto, { rejectWithValue }) => {
    try {
      const response = await axios.put<SimpleResponseDto | ErrorResponseDto>(`/auth/password/reset`, payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUserProfile = createAsyncThunk(
  'updateUserProfile',
  async (payload: UpdateUserProfileRequestDto, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const userGuid = state.authReducer.user.guid;
    try {
      const response = await axios.put<UpdateUserProfileResponseDto>(`/users/${userGuid}`, payload);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateUserEmail = createAsyncThunk(
  'updateUserEmail',
  async (payload: { email: string }, { rejectWithValue }) => {
    try {
      const response = await axios.put<any>(`/users/change-email`, payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const changeUserPassword = createAsyncThunk(
  'changeUserPassword',
  async (payload: { oldPassword: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await axios.put<SimpleResponseDto | ErrorResponseDto>(`/users/change-password`, payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<UsernameType>) => ({
      ...state,
      registrationForm: {
        ...state.registrationForm,
        username: action.payload,
      },
    }),
    setNewUserAvatar: (state, action: PayloadAction<{ url: string }>) => {
      if (state.user !== undefined) {
        return {
          ...state,
          user: {
            ...state.user,
            profileImageUrl: action.payload.url,
          },
        };
      }
      return { ...state };
    },
    setUserEmail: (state, action: PayloadAction<{ email: string }>) => ({
      ...state,
      registrationForm: {
        ...state.registrationForm,
        email: action.payload.email,
      },
    }),
    logout: (state) => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(loginBy.fulfilled, (state, action) => {
      const response = action.payload;
      if (!isLoginErrorResponse(response)) {
        const jwt: any = jwt_decode(response.jwt);
        const { exp } = jwt;
        return {
          ...state,
          user: mapUser(response),
          token: response.jwt,
          // eslint-disable-next-line no-magic-numbers
          tokenExpires: new Date(exp * 1000),
          registrationForm: undefined,
        };
      }
      return {
        ...state,
      };
    });

    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      const response: UpdateUserProfileResponseDto = action.payload;
      const { firstName, lastName } = getUserName(response.name);
      const rawProfileUrl = response.metadata.profile_url;
      const profileImageUrl = rawProfileUrl.includes('.co/media/')
        ? rawProfileUrl
        : response.metadata.profile_url.replace('.co/media', '.co/media/');
      return {
        ...state,
        user: {
          ...state.user,
          email: response.email,
          isVerified: response.email_verified,
          guid: response.guid,
          firstName,
          lastName,
          profileImageUrl,
        },
      };
    });
  },
});

export const { setUsername, setUserEmail, setNewUserAvatar, logout } = authReducer.actions;

export default authReducer.reducer;
