import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import './guest-message-form.scss';
import { useAppDispatch } from '../../../../store';
import { updateHomeGreetings } from '../../../../store/home/home-store';

import ToggleControl from '../../../../../shared/components/ToggleControl/ToggleControl';
import TextareaControl from '../../../../../shared/components/TextareaControl/TextareaControl';

import { MessagesDto } from '../../../../store/home/models/messagesDto';
import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { UpdateGreetingRequest } from '../../../../store/home/models/updateGreetingRequest';

type FormType = {
  homeMessageEnabled: boolean;
  homeMessage: string;
  quickFaqEnabled: boolean;
  quickFaq: string;
  thingsToDoEnabled: boolean;
  thingsToDo: string;
};

const MAX_MESSAGE_LENGTH = 500;

// eslint-disable-next-line sonarjs/cognitive-complexity
const GuestMessageForm = ({ data, guid, onSubmit }: Props) => {
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();
  const dispatch = useAppDispatch();

  const initialValues: FormType = {
    homeMessageEnabled: data?.greeting.enabled ?? true,
    homeMessage: data?.greeting.text ?? '',
    quickFaqEnabled: data?.needToKnow.enabled ?? true,
    quickFaq: data?.needToKnow.text ?? '',
    thingsToDoEnabled: data?.thingsToDo.enabled ?? false,
    thingsToDo: data?.thingsToDo.text ?? '',
  };

  const validationSchema = Yup.object().shape({
    homeMessage: Yup.string()
      .test('when-homeMessageEnabled', 'Required', function (value) {
        const v = value ?? '';
        // eslint-disable-next-line
        return !this.parent.homeMessageEnabled || this.parent.homeMessageEnabled && v.length > 0;
      })
      .max(MAX_MESSAGE_LENGTH, `Should be less than ${MAX_MESSAGE_LENGTH} symbols`),
    quickFaq: Yup.string()
      .test('when-quickFaqEnabled', 'Required', function (value) {
        const v = value ?? '';
        // eslint-disable-next-line
      return !this.parent.quickFaqEnabled || this.parent.quickFaqEnabled && v.length > 0;
      })
      .max(MAX_MESSAGE_LENGTH, `Should be less than ${MAX_MESSAGE_LENGTH} symbols`),
    thingsToDo: Yup.string()
      .test('when-thingsToDoEnabled', 'Required', function (value) {
        const v = value ?? '';
        // eslint-disable-next-line
      return !this.parent.thingsToDoEnabled || this.parent.thingsToDoEnabled && v.length > 0;
      })
      .max(MAX_MESSAGE_LENGTH, `Should be less than ${MAX_MESSAGE_LENGTH} symbols`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!guid) {
        return undefined;
      }
      const request: UpdateGreetingRequest = {
        messages: {
          faq: data?.faq ?? [],
          greeting: {
            enabled: values.homeMessageEnabled,
            text: values.homeMessage,
          },
          needToKnow: {
            enabled: values.quickFaqEnabled,
            text: values.quickFaq,
          },
          thingsToDo: {
            enabled: values.thingsToDoEnabled,
            text: values.thingsToDo,
          },
        },
        homeGiud: guid,
      };
      return submitForm(request);
    },
  });

  const submitForm = async (values: UpdateGreetingRequest) => {
    setComponentState('Loading');
    try {
      await dispatch(updateHomeGreetings(values)).unwrap();
      setComponentState('LoadedSuccessful');
      onSubmit();
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the saving process', error);
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <section className="layout__step-form mb4">
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="layout__description layout__description_step-form">Guest Notifications</div>
          <div className="layout__note">
            Add a little blurb for your guests as they navigate through your welcome experience.
          </div>
          <div className="guest-message-form__label">
            Home
            <span className="guest-message-form__toggle-box">
              {formik.values.homeMessageEnabled ? 'Enabled' : 'Disabled'}
              <ToggleControl name="homeMessageEnabled" className="guest-message-form__toggle" />
            </span>
          </div>
          <div className="layout__note guest-message-form__symbol-counter">
            {formik.values.homeMessage.length} of {MAX_MESSAGE_LENGTH} characters
          </div>
          <TextareaControl
            label="Message"
            name="homeMessage"
            className="guest-message-form__textarea"
            disabled={!formik.values.homeMessageEnabled || formik.isSubmitting}
          />

          <div className="guest-message-form__label">
            What you need to know (FAQ)
            <span className="guest-message-form__toggle-box">
              {formik.values.quickFaqEnabled ? 'Enabled' : 'Disabled'}
              <ToggleControl name="quickFaqEnabled" className="guest-message-form__toggle" />
            </span>
          </div>
          <div className="layout__note guest-message-form__symbol-counter">
            {formik.values.quickFaq.length} of {MAX_MESSAGE_LENGTH} characters
          </div>
          <TextareaControl
            label="Message"
            name="quickFaq"
            className="guest-message-form__textarea"
            disabled={!formik.values.quickFaqEnabled || formik.isSubmitting}
          />

          <div className="guest-message-form__label">
            Things to do
            <span className="guest-message-form__toggle-box">
              {formik.values.thingsToDoEnabled ? 'Enabled' : 'Disabled'}
              <ToggleControl name="thingsToDoEnabled" className="guest-message-form__toggle" />
            </span>
          </div>
          <div className="layout__note guest-message-form__symbol-counter">
            {formik.values.thingsToDo.length} of {MAX_MESSAGE_LENGTH} characters
          </div>
          <TextareaControl
            label="Message"
            name="thingsToDo"
            className="guest-message-form__textarea"
            disabled={!formik.values.thingsToDoEnabled || formik.isSubmitting}
          />
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: formik.isSubmitting || componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.touched && !formik.isValid}
          >
            Save
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">Error: {errorMessageRef.current}</div>
          </AnimateHeight>
          <AnimateHeight duration={350} height={componentState === 'LoadedSuccessful' ? 'auto' : 0}>
            <div className="form__api-message form__api-message_success">Successfully Updated</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </section>
  );
};

type Props = {
  data?: MessagesDto;
  guid?: string;
  onSubmit: () => void;
};

export default GuestMessageForm;
