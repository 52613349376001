import React from 'react';
import classNames from 'classnames';
import './error-message.scss';

import SvgIcon, { IconNames } from '../SvgIcon/SvgIcon';

const ErrorMessage = ({ className, children, icon }: Props) => {
  return (
    <section className={classNames('error-message', className)}>
      <div className="error-message__box">
        <SvgIcon name={icon ?? 'warning'} className="error-message__icon" />
        <div className="error-message__text">{children}</div>
      </div>
    </section>
  );
};

type Props = {
  className?: string;
  children: string;
  icon?: IconNames;
};

export default ErrorMessage;
