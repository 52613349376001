import { EffectCallback, useEffect, useRef } from 'react';

export const useMount = (effect: EffectCallback) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      // eslint-disable-next-line functional/immutable-data
      mounted.current = true;
      return effect();
    }

    // eslint-disable-next-line
    return () => {};
  }, []);
};
