import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../../store/auth/hooks/useAuth';
import { trackEvent } from '../../../../../setupAnalytics';
import { useMount } from '../../../../../shared/hooks/useMount';

const SighUpFinished = () => {
  const { user } = useAuth();
  const autoFocusElementRef = useRef<HTMLAnchorElement>(null);

  useMount(() => {
    trackEvent('registration-completed-confirmation-viewed');
    if (autoFocusElementRef.current) {
      autoFocusElementRef.current.focus();
    }
  });

  return (
    <section className="layout__sign-up">
      <h1 className="layout__sign-up-title layout__sign-up-title_alone">
        {user?.firstName},
        <br />
        We’re good to go!
      </h1>
      <div className="user-avatar__message">
        If you have any inquiries, please contact us by utilizing the help button located at the bottom right of your
        screen.
      </div>
      <div className="mb4">
        <Link
          to="/"
          className="form__submit button"
          ref={autoFocusElementRef}
          onClick={() => {
            trackEvent('host-account-created');
          }}
        >
          Confirm &amp; continue
        </Link>
      </div>
    </section>
  );
};

export default SighUpFinished;
