import { User } from '../models/user';
import { LoginResponseDto } from '../models/loginResponseDto';

export const mapUser = (userDto: LoginResponseDto): User => {
  const { firstName, lastName } = getUserName(userDto.name);

  return {
    guid: userDto.guid,
    email: userDto.email,
    isVerified: userDto.emailVerified,
    firstName,
    lastName,
    profileImageUrl: userDto.metadata.profile_url,
  };
};

export const getUserName = (name: string) => {
  const userName = name.split(' ');
  const firstName = userName[0];
  const lastName = [...userName.filter((_, index) => index !== 0)].join(' ');
  return { firstName, lastName };
};
