import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './legal-link.scss';

const LegalLinks = ({ className }: Props) => {
  return (
    <section className={classNames('legal-link', className)}>
      <Link to="/terms" className="legal-link__link" title="">
        Terms of service
      </Link>
      <Link to="/privacy" className="legal-link__link" title="">
        Privacy Policy
      </Link>
    </section>
  );
};

type Props = {
  className?: string;
};

export default LegalLinks;
