import React, { useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './email-verifier.scss';
import { useAppDispatch } from '../../../../store';
import { verificationEmail } from '../../../../store/auth/auth-store';
import { showToast } from '../../../../store/app/app-store';
import { useMount } from '../../../../../shared/hooks/useMount';

import LoaderPlaceholder from '../../../../../shared/components/LoaderPlaceholder/LoaderPlaceholder';
import ErrorMessage from '../../../../../shared/components/ErrorMessage/ErrorMessage';

import { isErrorResponse } from '../../../../../shared/guards/isErrorResponse';
import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';

const EmailVerifier = () => {
  const [searchParams] = useSearchParams();
  const [componentState, setComponentState] = useState<ApiInteractionState>('Loading');
  const errorMessageRef = useRef<string>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useMount(() => {
    const opt = searchParams.get('opt');
    if (!opt) {
      handleError('Wrong verification link');
      return;
    }
    verifyEmail(opt);
  });

  const verifyEmail = async (otp: string) => {
    try {
      const response = await dispatch(verificationEmail({ otp })).unwrap();
      if (isErrorResponse(response)) {
        handleError(response.error);
        return Promise.reject();
      }
      dispatch(showToast({ message: `Your email was successfully verified` }));
      navigate('/login');
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the email verification process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <>
      {componentState === 'Loading' && <LoaderPlaceholder />}
      {componentState === 'Error' && errorMessageRef.current !== undefined && (
        <ErrorMessage className="email-verifier__error">{errorMessageRef.current}</ErrorMessage>
      )}
    </>
  );
};

export default EmailVerifier;
