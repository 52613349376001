import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../../app/store/auth/hooks/useAuth';

const PrivateRoute = ({ children }: Props) => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return children;
};

type Props = {
  children: JSX.Element;
};

export default PrivateRoute;
