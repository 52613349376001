import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import './welcome-form.scss';

import useAuth from '../../../../store/auth/hooks/useAuth';
import { useAppDispatch, RootState } from '../../../../store';
import {
  updateHomeScreen,
  clearUploadingProgress,
  getMediaUploadLink,
  uploadMediaS3,
} from '../../../../store/home/home-store';
import { showToast } from '../../../../store/app/app-store';
import { waitResourceReadiness } from '../../../../../shared/utils/waitResourceReadiness';

import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';
import InputControl from '../../../../../shared/components/InputControl/InputControl';

import { HomeScreenDto } from '../../../../store/home/models/homeScreenDto';
import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { MediaLinkResponseDto } from '../../../../store/home/models/mediaLinkResponseDto';
import { ResourceUploadingType } from '../../../../store/home/models/resourceUploadingType';
import { trackEvent } from '../../../../../setupAnalytics';

const MAX_WELCOME_TITLE = 140;
const MAX_PROGRESS = 100;

type FormType = {
  readonly caption: string;
};

const validationSchema = Yup.object().shape({
  caption: Yup.string().required('Required').max(MAX_WELCOME_TITLE, `Should be less than ${MAX_WELCOME_TITLE} symbols`),
});

const WelcomeForm = ({ data, guid, onSubmitted, onFrameUrlsStateChanged }: Props) => {
  const { user } = useAuth();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [backgroundError, setBackgroundError] = useState<string>('');
  const [backgroundDataUrl, setBackgroundDataUrl] = useState<string>();
  const [welcomeVideoError, setWelcomeVideoError] = useState<string>('');
  const [welcomeVideoDataUrl, setWelcomeVideoDataUrl] = useState<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoUploadUrls, setVideoUploadUrls] = useState<MediaLinkResponseDto | undefined>(undefined);
  const [imageUploadUrls, setImageUploadUrls] = useState<MediaLinkResponseDto | undefined>(undefined);
  const [serverProcessingState, setServerProcessingState] = useState<{
    imageProcessing: boolean;
    videoProcessing: boolean;
  }>({ imageProcessing: false, videoProcessing: false });
  const [isPreventUiBlocking, setIsPreventUiBlocking] = useState(false);

  const { imageUploadingProgress, videoUploadingProgress, isUIBlockedTooLong } = useSelector((state: RootState) => ({
    imageUploadingProgress: state.homeReducer.imageUploadingProgress,
    videoUploadingProgress: state.homeReducer.videoUploadingProgress,
    isUIBlockedTooLong: state.homeReducer.isUIBlockedTooLong,
  }));
  const imageProgressWidth = Math.round(MAX_PROGRESS * imageUploadingProgress);
  const videoProgressWidth = Math.round(MAX_PROGRESS * videoUploadingProgress);
  const initialValues: FormType = {
    caption: data?.caption ?? '',
  };
  const hasVideoInForm = useRef<boolean>();
  const hasPictureInForm = useRef<boolean>();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!guid || !user) {
        return;
      }
      if (!formData.has('homeGuid')) {
        formData.append('homeGuid', guid);
      } else {
        formData.set('homeGuid', guid);
      }
      saveWelcomeForm(formData, guid, values.caption);
    },
  });

  const saveWelcomeForm = async (fData: FormData, homeGiud: string, caption: string) => {
    trackEvent('welcome-screen-revised');
    setComponentState('Loading');
    setIsPreventUiBlocking(false);
    dispatch(clearUploadingProgress());
    const [imageUploadingResult, videoUploadingResult] = await Promise.all([uploadImage(fData), uploadVideo(fData)]);
    await submitWelcomeScreenData({
      homeGiud,
      caption,
      backgroundUrl: imageUploadingResult ? imageUploadingResult.url : data?.backgroundUrl ?? undefined,
      mediaUrl: videoUploadingResult ? videoUploadingResult.url : data?.mediaUrl ?? undefined,
    });
    trackWelcomeScreenUpdatingProcess({
      isCaptionChanged: data?.caption !== caption,
      isImageChanged: Boolean(imageUploadingResult && imageUploadingResult.url),
      isVideoChanged: Boolean(videoUploadingResult && videoUploadingResult.url),
    });
    dispatch(showToast({ message: `Welcome screen is successfully updated` }));
    onFrameUrlsStateChanged({
      isImageUrlProcessing: Boolean(imageUploadingResult),
      isVideoUrlProcessing: Boolean(videoUploadingResult),
    });
    setServerProcessingState({
      imageProcessing: Boolean(imageUploadingResult),
      videoProcessing: Boolean(videoUploadingResult),
    });
    checkResourceUrlAvailability({ url: imageUploadingResult?.url, resourceType: 'image' }).then((isImageReady) => {
      if (isImageReady === false) {
        dispatch(
          showToast({
            message: `The server has spent too much time on image processing. Please reload the page a few moments later to see the uploaded image.`,
            type: 'Error',
          }),
        );
      }
      onFrameUrlsStateChanged({ isImageUrlProcessing: !isImageReady });
      setServerProcessingState((state) => ({ ...state, imageProcessing: !isImageReady }));
    });
    checkResourceUrlAvailability({ url: videoUploadingResult?.url, resourceType: 'video' }).then((isVideoReady) => {
      if (isVideoReady === false) {
        dispatch(
          showToast({
            message: `The server has spent too much time on video processing. Please reload the page a few moments later to see the uploaded video.`,
            type: 'Error',
          }),
        );
      }
      onFrameUrlsStateChanged({ isVideoUrlProcessing: !isVideoReady });
      setServerProcessingState((state) => ({ ...state, videoProcessing: !isVideoReady }));
    });
    onSubmitted();
  };

  const uploadVideo = async (fData: FormData) => {
    if (!videoUploadUrls || !fData.has('welcomeVideo')) {
      return undefined;
    }
    // eslint-disable-next-line functional/immutable-data
    hasVideoInForm.current = true;
    try {
      await dispatch(
        uploadMediaS3({
          link: videoUploadUrls.link,
          data: fData.get('welcomeVideo'),
          resourceType: 'video',
        }),
      );
      fData.delete('welcomeVideo');
      // eslint-disable-next-line functional/immutable-data
      hasVideoInForm.current = false;

      return {
        url: videoUploadUrls.resultFilename,
      };
    } catch (e) {
      handleError('Server did not upload the file');
    }
    return undefined;
  };

  const uploadImage = async (fData: FormData) => {
    if (!imageUploadUrls || !fData.has('background')) {
      return undefined;
    }
    // eslint-disable-next-line functional/immutable-data
    hasPictureInForm.current = true;
    try {
      await dispatch(
        uploadMediaS3({
          link: imageUploadUrls.link,
          data: fData.get('background'),
          resourceType: 'image',
        }),
      );
      fData.delete('background');
      // eslint-disable-next-line functional/immutable-data
      hasPictureInForm.current = false;

      return {
        url: imageUploadUrls.resultFilename,
      };
    } catch (e) {
      handleError('Server did not upload the file');
    }
    return undefined;
  };

  const checkResourceUrlAvailability = ({
    url,
    resourceType,
  }: {
    url?: string;
    resourceType?: ResourceUploadingType;
  }) => {
    if (!url) {
      return Promise.resolve(true);
    }
    if (resourceType === 'image') {
      return waitResourceReadiness(url);
    }
    const VIDEO_CHECKING_MAX_ATTEMPTS = 60;
    return waitResourceReadiness(url, 'video', VIDEO_CHECKING_MAX_ATTEMPTS);
  };

  const submitWelcomeScreenData = async ({
    homeGiud,
    caption,
    backgroundUrl,
    mediaUrl,
  }: {
    homeGiud: string;
    caption: string;
    backgroundUrl: string | undefined;
    mediaUrl: string | undefined;
  }) => {
    try {
      await dispatch(
        updateHomeScreen({
          homeGiud,
          caption,
          backgroundUrl,
          mediaUrl,
        }),
      ).unwrap();
      setComponentState('LoadedSuccessful');
    } catch (error: any) {
      handleError(error?.message ?? 'Unknown image uploading error', error);
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  const handleChangeBackground = (event: any) => {
    if (event.target.files.length <= 0) {
      return;
    }
    setBackgroundError('');
    const file: File = event.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setBackgroundError('Supports jpg and png files format only');
      return;
    }
    const newFormData = new FormData();
    if (formData.has('welcomeVideo')) {
      newFormData.append('welcomeVideo', formData.get('welcomeVideo') ?? '');
    }
    newFormData.append('background', file);
    setFormData(newFormData);
    setBackgroundDataUrl(URL.createObjectURL(file));

    dispatch(getMediaUploadLink({ fileType: 'background' }))
      .unwrap()
      .then((response) => {
        setImageUploadUrls(response);
      });
  };

  const handleChangeWelcomeVideo = (event: any) => {
    if (event.target.files.length <= 0) {
      return;
    }
    setWelcomeVideoError('');
    const file: File = event.target.files[0];
    if (file.type !== 'video/mp4' && file.type !== 'video/quicktime') {
      setWelcomeVideoError('Supports .mp4 or .mov file formats only');
      return;
    }
    const newFormData = new FormData();
    if (formData.has('background')) {
      newFormData.append('background', formData.get('background') ?? '');
    }
    newFormData.append('welcomeVideo', file);
    setFormData(newFormData);
    setWelcomeVideoDataUrl(URL.createObjectURL(file));

    dispatch(getMediaUploadLink({ fileType: 'welcomeVideo' }))
      .unwrap()
      .then((response) => {
        setVideoUploadUrls(response);
      });
  };

  const getBackgroundUrl = (): string => {
    if (backgroundDataUrl) {
      return `url(${backgroundDataUrl})`;
    }
    if (data?.backgroundUrl && !serverProcessingState.imageProcessing) {
      return `url(${data.backgroundUrl})`;
    }
    return '';
  };

  const getVideoUrl = (): string => {
    if (welcomeVideoDataUrl) {
      return welcomeVideoDataUrl;
    }

    if (data?.mediaUrl && !serverProcessingState.videoProcessing) {
      return data?.mediaUrl;
    }

    return '';
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const checkResourcesAvailability = async () => {
    if (!data) {
      return;
    }
    const [isImageAvailable, isVideoAvailable] = await Promise.all([
      waitResourceReadiness(`${data.backgroundUrl}?touch`, 'image', 1),
      waitResourceReadiness(`${data.mediaUrl}?touch`, 'video', 1),
    ]);
    if (isImageAvailable && isVideoAvailable) {
      return;
    }
    onFrameUrlsStateChanged({
      isImageUrlProcessing: !isImageAvailable && Boolean(data.backgroundUrl),
      isVideoUrlProcessing: !isVideoAvailable && Boolean(data.mediaUrl),
    });
    setServerProcessingState({
      imageProcessing: !isImageAvailable && Boolean(data.backgroundUrl),
      videoProcessing: !isVideoAvailable && Boolean(data.mediaUrl),
    });

    if (!isImageAvailable && Boolean(data.backgroundUrl)) {
      checkResourceUrlAvailability({ url: data.backgroundUrl, resourceType: 'image' }).then((isImageReady) => {
        if (isImageReady === false) {
          dispatch(
            showToast({
              message: `The server has spent too much time on image processing. Please reload the page a few moments later to see the uploaded image.`,
              type: 'Error',
            }),
          );
        }
        onFrameUrlsStateChanged({ isImageUrlProcessing: !isImageReady });
        setServerProcessingState((state) => ({ ...state, imageProcessing: !isImageReady }));
      });
    }

    if (!isVideoAvailable && Boolean(data.mediaUrl)) {
      checkResourceUrlAvailability({ url: data.mediaUrl, resourceType: 'video' }).then((isVideoReady) => {
        if (isVideoReady === false) {
          dispatch(
            showToast({
              message: `The server has spent too much time on video processing. Please reload the page a few moments later to see the uploaded video.`,
              type: 'Error',
            }),
          );
        }
        onFrameUrlsStateChanged({ isVideoUrlProcessing: !isVideoReady });
        setServerProcessingState((state) => ({ ...state, videoProcessing: !isVideoReady }));
      });
    }
  };

  const trackWelcomeScreenUpdatingProcess = ({
    isCaptionChanged,
    isImageChanged,
    isVideoChanged,
  }: {
    isCaptionChanged: boolean;
    isImageChanged: boolean;
    isVideoChanged: boolean;
  }) => {
    if (isCaptionChanged) {
      trackEvent('welcome-message-engaged');
    }
    if (isImageChanged) {
      trackEvent('background-image-added');
    }
    if (isVideoChanged) {
      trackEvent('welcome-video-added');
    }
  };

  useEffect(() => {
    setVideoUrl(getVideoUrl());
  }, [welcomeVideoDataUrl, data?.mediaUrl, serverProcessingState.videoProcessing]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  useEffect(() => {
    checkResourcesAvailability();
  }, [data]);

  useEffect(() => {
    if (isUIBlockedTooLong) {
      dispatch(
        showToast({
          message: `It seems the server is seriously working. You may decide to interrupt it and load other resources.`,
          type: 'Neutral',
          noAutoClose: true,
          button: {
            title: 'Yes, stop waiting',
            callback: () => {
              setIsPreventUiBlocking(true);
            },
            closeAfterCallback: true,
          },
        }),
      );
    }
  }, [isUIBlockedTooLong]);

  return (
    <section className="layout__step-form mb4">
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="layout__description layout__description_step-form">Background Image</div>
          <div className="layout__note">
            For the best results, we recommend sizes <strong className="layout__nobr">1920 x 1080</strong>. Use{' '}
            <a
              href="https://unsplash.com/"
              target="_blank"
              title="Beautiful Free Images &amp; Pictures | Unsplash"
              rel="nofollow noreferrer"
            >
              Unsplash
            </a>{' '}
            for free stock photography.
          </div>

          <div className="welcome-form__preview-box">
            <label
              className={classNames('button button_round', {
                button_loading:
                  componentState === 'Loading' || (serverProcessingState.imageProcessing && !isPreventUiBlocking),
              })}
            >
              <SvgIcon name="plus" className="button__icon" />
              <input type="file" onChange={handleChangeBackground} className="visually-hidden" />
            </label>
            <div
              className="welcome-form__preview welcome-form__preview_background"
              style={{
                backgroundImage: getBackgroundUrl(),
              }}
            >
              <span
                className={classNames('welcome-form__uploading-progress', {
                  'welcome-form__uploading-progress_active': componentState === 'Loading',
                })}
              >
                {componentState === 'Loading' && hasPictureInForm.current && (
                  <>
                    <i className="welcome-form__progress-bar" style={{ width: `${imageProgressWidth}%` }} />
                    <i className="welcome-form__progress-value">
                      {imageProgressWidth - (imageProgressWidth < 1 ? 0 : 1)}%
                    </i>
                  </>
                )}
              </span>
            </div>
          </div>
          <AnimateHeight duration={350} height={backgroundError !== '' ? 'auto' : 0}>
            <div className="form__api-message mb4">{backgroundError}</div>
          </AnimateHeight>
          <div className="layout__description layout__description_step-form">
            <div className="welcome-form__label-box">
              Welcome message
              <span className="welcome-form__label">
                {formik.values.caption.length} of {MAX_WELCOME_TITLE} characters
              </span>
            </div>
          </div>
          <InputControl
            label="Caption"
            name="caption"
            className="form__row"
            disabled={formik.isSubmitting}
            maxlength={MAX_WELCOME_TITLE}
          />
          <div className="layout__description layout__description_step-form mt2">Welcome Video</div>
          <div className="layout__note">Welcome your guests with a short video (2mins max). Show the love!</div>
          <div className="welcome-form__preview-box">
            <label
              className={classNames('button button_round', {
                button_loading:
                  componentState === 'Loading' || (serverProcessingState.videoProcessing && !isPreventUiBlocking),
              })}
            >
              <SvgIcon name="plus" className="button__icon" />
              <input type="file" onChange={handleChangeWelcomeVideo} className="visually-hidden" />
            </label>

            {videoUrl !== '' ? (
              <div className="welcome-form__media-box">
                <video
                  ref={videoRef}
                  controls
                  disablePictureInPicture
                  disableRemotePlayback
                  className="welcome-form__media-preview"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  muted
                  preload="auto"
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <span
                  className={classNames('welcome-form__uploading-progress', {
                    'welcome-form__uploading-progress_active': componentState === 'Loading',
                  })}
                >
                  {componentState === 'Loading' && hasVideoInForm.current && (
                    <>
                      <i className="welcome-form__progress-bar" style={{ width: `${videoProgressWidth}%` }} />
                      <i className="welcome-form__progress-value">
                        {videoProgressWidth - (videoProgressWidth < 1 ? 0 : 1)}%
                      </i>
                    </>
                  )}
                </span>
              </div>
            ) : (
              <div className="welcome-form__preview welcome-form__preview_media" />
            )}
          </div>
          <AnimateHeight duration={350} height={welcomeVideoError !== '' ? 'auto' : 0}>
            <div className="form__api-message mb4">{welcomeVideoError}</div>
          </AnimateHeight>
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.touched && !formik.isValid}
          >
            Save
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">Error: {errorMessageRef.current}</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </section>
  );
};

type Props = {
  readonly data?: HomeScreenDto;
  readonly guid?: string;
  readonly onSubmitted: () => void;
  readonly onFrameUrlsStateChanged: (state: { isImageUrlProcessing?: boolean; isVideoUrlProcessing?: boolean }) => void;
};

export default WelcomeForm;
