import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './input-control.scss';

import { useField } from 'formik';
import { wrongDigitSymbols } from '../../models/wrongDigitSymbols';

const TIME_FOR_PREVENT_BROWSER_SUGGEST_AUTOCOMPLETE = 10;

const InputControl = (props: Props) => {
  const [field, meta, helpers] = useField<string>(props.name);
  const [value, setValue] = useState<string>('');
  // const [isReadonly, setIsReadonly] = useState<boolean | undefined>(false);
  const input = useRef<any>(null);

  useEffect(() => {
    if (props.focus) {
      // eslint-disable-next-line functional/immutable-data
      props.focus.current = setFocus;
    }
  }, []);

  useEffect(() => {
    if (props.isCapitalize && value.length === 1 && value[0].toUpperCase() !== value[0]) {
      helpers.setValue(value.toUpperCase());
    }
  }, [value, props.isCapitalize]);

  useEffect(() => {
    if (props.isAutoFocus) {
      input.current.focus();
    }
  }, [props.isAutoFocus]);

  // useEffect(() => {
  //   setIsReadonly(props.isAutocompleteOff);
  // }, [props.isAutocompleteOff]);

  const setFocus = () => {
    input.current.focus();
  };

  return (
    <section
      className={classNames('input-control', props.className, {
        'input-control_error': meta.touched && meta.error,
        'input-control_disabled': props.disabled,
      })}
    >
      <div className="input-control__box">
        <input
          {...field}
          type={props.type ? props.type : 'text'}
          className={classNames('input-control__input', {
            'input-control__input_has-icon': props.children,
            'input-control__input_uppercase': props.isUpperCase,
          })}
          disabled={props.disabled}
          // readOnly={props.isAutocompleteOff && isReadonly}
          autoComplete={props.isAutocompleteOff ? 'off' : undefined}
          maxLength={props.maxlength}
          onKeyDown={(event) => {
            if (props.type === 'number' && wrongDigitSymbols.includes(event.key)) {
              event.preventDefault();
            }
          }}
          onKeyUp={(event) => {
            if (props.isCapitalize) {
              setValue(field.value);
            }
          }}
          ref={input}
          onFocus={() => {
            // if (props.isAutocompleteOff) {
            //   setTimeout(() => {
            //     setIsReadonly(false);
            //   }, TIME_FOR_PREVENT_BROWSER_SUGGEST_AUTOCOMPLETE);
            // }
            if (props.onFocus) {
              props.onFocus(true);
            }
            if (props.noFocusable) {
              input.current.blur();
            }
            if (props.isSelectAll) {
              input.current.select();
            }
          }}
          onBlur={(e) => {
            // if (props.isAutocompleteOff) {
            //   setIsReadonly(true);
            // }
            if (props.onFocus) {
              props.onFocus(false);
            }
            if (props.type === 'number' && typeof field.value === 'string') {
              helpers.setValue(' ');
            }
            if (props.isCapitalize && value.length > 0) {
              helpers.setValue(`${value.charAt(0).toUpperCase()}${value.slice(1)}`);
            }
            if (props.isUpperCase) {
              helpers.setValue(field.value.toUpperCase());
            }
            field.onBlur(e);
          }}
        />
        <label
          className={classNames('input-control__label', {
            'input-control__label_active': Boolean(field.value),
          })}
        >
          {props.label}
          {props.required && <span className="input-control__label-asterisk">{' '}*</span>}
        </label>
        <span className="input-control__bottom-border" />
        {!props.hideAllMessages && props.message && !meta.error && (
          <span className="input-control__message">{props.message}</span>
        )}
        {!props.hideAllMessages && meta.touched && meta.error && (
          <span className="input-control__message">{meta.error}</span>
        )}
        {props.children}
      </div>
    </section>
  );
};

type Props = {
  label: string;
  name: string;
  type?: string;
  className?: string;
  message?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  hideAllMessages?: boolean;
  isCapitalize?: boolean;
  isAutoFocus?: boolean;
  required?: boolean;
  isAutocompleteOff?: boolean;
  onFocus?: (isFocused: boolean) => void;
  noFocusable?: boolean;
  isSelectAll?: boolean;
  focus?: React.MutableRefObject<(() => void) | undefined>;
  maxlength?: number;
  isUpperCase?: boolean;
};

export default InputControl;
