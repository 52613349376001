import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, RootState } from '../../../../store';
import { setUserEmail } from '../../../../store/auth/auth-store';

import InputControl from '../../../../../shared/components/InputControl/InputControl';

type FormType = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('It should be valid email address'),
});

const initialValues: FormType = {
  email: '',
};

const EmailForm = ({ className, onSubmitted }: Props) => {
  const dispatch = useAppDispatch();

  const { registrationForm } = useSelector((state: RootState) => ({
    registrationForm: state.authReducer.registrationForm,
  }));

  useEffect(() => {
    const email = registrationForm?.email;
    if (email === undefined) {
      return;
    }

    formik.setValues({
      email,
    });
  }, [registrationForm]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      dispatch(setUserEmail({ email: values.email }));
      if (onSubmitted) {
        onSubmitted();
      }
    },
  });

  return (
    <div className={classNames('email-form', className)}>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl
            type="email"
            label="Email"
            name="email"
            className="form__row"
            disabled={formik.isSubmitting}
            isAutoFocus
          />
          <button
            className="form__submit button button_inline"
            type="submit"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Next
          </button>
        </form>
      </FormikProvider>
    </div>
  );
};

type Props = {
  className?: string;
  onSubmitted?: () => void;
};

export default EmailForm;
