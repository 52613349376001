import React, { useEffect } from 'react';
import './App.scss';
import { useLocationHistory } from './shared/hooks/useLocationHistory';
import { useFreshworkWidget } from './shared/hooks/useFreshworkWidget';

import AppRoutes from './app/AppRoutes';
import AppHeader from './shared/components/AppHeader/AppHeader';
import AppToasts from './shared/components/AppToasts/AppToasts';

const App = () => {
  const { currentUrl } = useLocationHistory();
  useFreshworkWidget();

  useEffect(() => {
    window.scroll(0, 0);
  }, [currentUrl]);

  return (
    <>
      <AppHeader />
      <AppRoutes />
      <AppToasts />
    </>
  );
};

export default App;
