import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch } from '../../../../store';
import { resendVerificationLink } from '../../../../store/auth/auth-store';
import { isErrorResponse } from '../../../../../shared/guards/isErrorResponse';
import useAuth from '../../../../store/auth/hooks/useAuth';

import BackButton from '../../components/BackButton/BackButton';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';

const SignUpFinish = () => {
  const autoFocusElementRef = useRef<HTMLAnchorElement>(null);
  const errorMessageRef = useRef<string>();
  const successMessageRef = useRef<string>();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();

  useEffect(() => {
    if (autoFocusElementRef.current) {
      autoFocusElementRef.current.focus();
    }
  }, []);

  const resendVerification = async () => {
    setComponentState('Loading');
    try {
      const response = await dispatch(resendVerificationLink()).unwrap();
      if (isErrorResponse(response)) {
        handleError(response.error);
        return Promise.reject();
      }
      // eslint-disable-next-line functional/immutable-data
      successMessageRef.current = 'Message with verification link was successfully sent';
      setComponentState('LoadedSuccessful');
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the sign up process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <section className="layout__sign-up layout__sign-up_small-form">
      {!isAuth && <BackButton to="/sign-up/password" />}
      <div>
        <h1 className="layout__sign-up-title">Let&apos;s get started!</h1>
        <div className="layout__sign-up-text mb4">Check your email for a confirmation code.</div>
        <div className="layout__sign-up-text mb4">Click the link to continue with registration.</div>
        {isAuth && (
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: componentState === 'Loading',
            })}
            type="button"
            onClick={() => {
              resendVerification();
            }}
          >
            Resend
          </button>
        )}
        <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
          <div className="form__api-message">Error: {errorMessageRef.current}</div>
        </AnimateHeight>
        <AnimateHeight duration={350} height={componentState === 'LoadedSuccessful' ? 'auto' : 0}>
          <div className="form__api-message form__api-message_success">{successMessageRef.current}</div>
        </AnimateHeight>
      </div>
    </section>
  );
};

export default SignUpFinish;
