import React from 'react';
import { Link } from 'react-router-dom';
import './sign-in.scss';
import PageFrame from '../../../../../shared/components/PageFrame/PageFrame';
import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';
import LoginForm from '../../components/LoginForm/LoginForm';
import LegalLinks from '../../components/LegalLinks/LegalLinks';

import { PromoImage } from '../../../../../shared/models/promoImage';

const SignIn = () => {
  return (
    <div className="container">
      <PageFrame imageUrl={PromoImage.Evening}>
        <section className="sign-in">
          <main className="sign-in__box">
            <SvgIcon name="logo" className="sign-in__logo" />
            <LoginForm className="sign-in__form" />
            <hr className="layout__hr" />
            <div className="layout__description">
              New to this?{' '}
              <Link to="/sign-up" className="layout__link layout__link_strong">
                Create an account here!
              </Link>
            </div>
            <div className="layout__description">
              <Link to="/password-recovery" className="layout__link">
                Forgot your password?
              </Link>
            </div>
          </main>
          <LegalLinks className="sign-in__footer" />
        </section>
      </PageFrame>
    </div>
  );
};

export default SignIn;
