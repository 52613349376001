import React from 'react';

import BackButton from '../../components/BackButton/BackButton';
import EmailForm from '../../components/EmailForm/EmailForm';

const SignUpStep3 = ({ onEmailSubmitted }: Props) => {
  const handleEmailSubmit = () => {
    onEmailSubmitted();
  };

  return (
    <section className="layout__sign-up layout__sign-up_small-form">
      <BackButton to="/sign-up/welcome" />
      <h1 className="layout__sign-up-title">Let&apos;s get started!</h1>
      <div className="layout__sign-up-text">Whats your email address?</div>
      <EmailForm className="layout__sign-up-form" onSubmitted={handleEmailSubmit} />
    </section>
  );
};

type Props = {
  onEmailSubmitted: () => void;
};

export default SignUpStep3;
