import React from 'react';
import classNames from 'classnames';
import './section-header.scss';

const SectionHeader = ({ className, children }: Props) => {
  return (
    <h1 className={classNames('section-header', className)}>
      <span className="section-header__text">{children}</span>
    </h1>
  );
};

type Props = {
  className?: string;
  children: string;
};

export default SectionHeader;
