import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, RootState } from '../../../../store';
import { showToast } from '../../../../store/app/app-store';
import { getUserHomes, linkDeviceToHome, unlinkUserHomeDevice } from '../../../../store/home/home-store';
import './properties.scss';

import SectionHeader from '../../../../../shared/components/SectionHeader/SectionHeader';
import LoaderPlaceholder from '../../../../../shared/components/LoaderPlaceholder/LoaderPlaceholder';
import BackButton from '../../../auth/components/BackButton/BackButton';
import InputControl from '../../../../../shared/components/InputControl/InputControl';
import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';
import PropertyInfoForm from '../../../auth/components/PropertyInfoForm/PropertyForm';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';

type FormType = {
  rokuPin: string;
};

const validationSchema = Yup.object().shape({
  rokuPin: Yup.string().required('Required'),
});

const initialValues: FormType = {
  rokuPin: '',
};

const Properties = () => {
  const dispatch = useAppDispatch();
  const { activeHomeAddress, activeHomeRokuDevices } = useSelector((state: RootState) => ({
    activeHomeAddress: state.homeReducer.activeHomeAddress,
    activeHomeRokuDevices: state.homeReducer.activeHomeRokuDevices,
  }));
  const [componentState, setComponentState] = useState<ApiInteractionState>(
    activeHomeAddress === undefined ? 'Loading' : 'LoadedSuccessful',
  );
  const [linkApiState, setLinkApiState] = useState<ApiInteractionState>();
  const [deviceIdUnderDelete, setDeviceIdUnderDelete] = useState<string | undefined>();
  const [deviceIdUnderDeleting, setDeviceIdUnderDeleting] = useState<string | undefined>();
  const [activePage, setActivePage] = useState<'Default' | 'NewDevice' | 'AddressChange'>('Default');
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      return linkRokuDevice(values.rokuPin);
    },
  });

  const linkRokuDevice = async (rokuPin: string) => {
    setLinkApiState('Loading');
    try {
      await dispatch(linkDeviceToHome({ rokuPin })).unwrap();
      setLinkApiState('LoadedSuccessful');
      formik.resetForm();
      setActivePage('Default');
      getHomes();
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the linking Roku device process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    dispatch(showToast({ message, type: 'Error' }));
  };

  useEffect(() => {
    if (!activeHomeAddress) {
      getHomes();
    }
  }, [activeHomeAddress]);

  const getHomes = async () => {
    try {
      setComponentState('Loading');
      await dispatch(getUserHomes()).unwrap();
      setComponentState('LoadedSuccessful');
    } catch (error) {
      setComponentState('Error');
    }
  };

  const handleUnlink = (e: React.SyntheticEvent, deviceId: string) => {
    e.preventDefault();
    setDeviceIdUnderDelete(deviceId);
  };

  const handleCloseConfirm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDeviceIdUnderDelete(undefined);
  };

  const handleUnlinkDevice = async () => {
    if (deviceIdUnderDelete === undefined) {
      return;
    }
    try {
      setDeviceIdUnderDelete(undefined);
      setDeviceIdUnderDeleting(deviceIdUnderDelete);
      await dispatch(unlinkUserHomeDevice({ deviceId: deviceIdUnderDelete })).unwrap();
      setDeviceIdUnderDeleting(undefined);
      getHomes();
      dispatch(showToast({ message: 'Successfully unlinked' }));
    } catch (error) {
      dispatch(showToast({ message: 'Error happens during unlinking device', type: 'Error' }));
    }
  };

  return (
    <div className={classNames('properties__box', { properties__box_pl: activePage !== 'Default' })}>
      {componentState === 'Loading' && <LoaderPlaceholder />}
      {componentState === 'LoadedSuccessful' && (
        <>
          {activeHomeAddress && (
            <section className="mb4">
              <div className="properties__address">
                {`${activeHomeAddress?.street}, ${activeHomeAddress?.city}, ${activeHomeAddress?.state}, ${activeHomeAddress?.postal_code}`}
                {activePage !== 'AddressChange' && (
                  <aside className="properties__edit-button-box">
                    <button
                      type="button"
                      className="properties__edit-button"
                      title="Edit Address"
                      onClick={() => setActivePage('AddressChange')}
                    >
                      (<SvgIcon name="edit" className="properties__edit-icon" /> Edit)
                    </button>
                  </aside>
                )}
              </div>
            </section>
          )}
          {activePage === 'Default' && (
            <>
              <SectionHeader className="mb2">YOUR DEVICES</SectionHeader>
              <main className="properties__device-list">
                {activeHomeRokuDevices !== null && activeHomeRokuDevices.length > 0 && (
                  <>
                    {activeHomeRokuDevices.map((device, index) => (
                      <div
                        className={classNames('properties__device-card-box', {
                          'properties__device-card-box_loading': deviceIdUnderDeleting === device.deviceId,
                        })}
                        key={device.deviceId}
                      >
                        <section className="properties__device-card">Device {index + 1}</section>
                        <a
                          href="#"
                          className="properties__device-unlink"
                          title=""
                          onClick={(e) => {
                            handleUnlink(e, device.deviceId);
                          }}
                        >
                          Unlink
                        </a>
                      </div>
                    ))}
                  </>
                )}
                <button
                  className="properties__device-card properties__device-card_link-button"
                  type="button"
                  onClick={() => setActivePage('NewDevice')}
                >
                  Setup a new device
                </button>
              </main>
            </>
          )}
          {activePage === 'NewDevice' && (
            <div className="properties__form">
              <BackButton className="properties__back-link" onClick={() => setActivePage('Default')} />
              <h1 className="layout__sign-up-title">Add new device</h1>
              <p className="layout__description">
                If you haven’t already downloaded the <strong>Home Guide</strong> Roku application,{' '}
                <a
                  href="https://www.youtube.com/watch?v=PtAl-qZ-5Qg"
                  target="_blank"
                  rel="nofollow noreferrer"
                  className="layout__link layout__link_green"
                >
                  Click here for instructions
                </a>
              </p>
              <p className="layout__description">Once the download is complete, enter the link code below:</p>
              <div className="layout__sign-up-text mb4">Enter the link code below</div>
              <div className={classNames('link-device-form')}>
                <FormikProvider value={formik}>
                  <form className="form" onSubmit={formik.handleSubmit}>
                    <InputControl
                      label="Link Code"
                      name="rokuPin"
                      className="form__row"
                      disabled={formik.isSubmitting}
                    />
                    <button
                      className="button mt2"
                      type="submit"
                      disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                    >
                      Confirm link code
                    </button>
                  </form>
                </FormikProvider>
              </div>
            </div>
          )}
          {activePage === 'AddressChange' && (
            <div className="properties__form">
              <BackButton className="properties__back-link" onClick={() => setActivePage('Default')} />
              <h1 className="layout__sign-up-title">Edit Property Address</h1>
              <PropertyInfoForm
                editDate={{
                  addressLine1: activeHomeAddress?.street ?? '',
                  addressLine2: '',
                  city: activeHomeAddress?.city ?? '',
                  state: activeHomeAddress?.state ?? '',
                  postalCode: activeHomeAddress?.postal_code ?? '',
                }}
                onSubmitted={() => {
                  setActivePage('Default');
                }}
              />
            </div>
          )}
        </>
      )}
      <aside
        className={classNames('properties__confirm-box', {
          'properties__confirm-box_shown': deviceIdUnderDelete !== undefined,
        })}
      >
        <section className="properties__confirm">
          <div className="properties__confirm-description">
            Are you sure you want to unlink this device from your Home Guide account?{' '}
          </div>
          <button className="button button_ttn mt2" type="button" onClick={handleUnlinkDevice}>
            Yes, unlink this device
          </button>
          <a href="#" className="button-link mt2" title="" onClick={handleCloseConfirm}>
            No, keep this device linked
          </a>
        </section>
      </aside>
    </div>
  );
};

export default Properties;
