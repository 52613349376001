import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch } from '../../../../store';
import { createHome, updateHomeAddress } from '../../../../store/home/home-store';

import InputControl from '../../../../../shared/components/InputControl/InputControl';

import { CreateHomeRequestDto } from '../../../../store/home/models/createHomeRequestDto';
import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { trackEvent } from '../../../../../setupAnalytics';

type FormType = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
};

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Required'),
  addressLine2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
});

const PropertyInfoForm = ({ className, editDate, onSubmitted }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const errorMessageRef = useRef<string>();
  const isEdit = editDate !== undefined;

  const initialValues: FormType = {
    addressLine1: editDate?.addressLine1 ?? '',
    addressLine2: editDate?.addressLine2 ?? '',
    city: editDate?.city ?? '',
    state: editDate?.state ?? '',
    postalCode: editDate?.postalCode ?? '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const request: CreateHomeRequestDto = {
        address: {
          postalCode: values.postalCode.trim(),
          city: values.city.trim(),
          state: values.state.trim(),
          street: `${values.addressLine1.trim()} ${values.addressLine2.trim()}`,
        },
      };
      submitForm(request);
    },
  });

  const submitForm = async (payload: CreateHomeRequestDto) => {
    setComponentState('Loading');
    try {
      const response = await dispatch(isEdit ? updateHomeAddress(payload) : createHome(payload)).unwrap();
      if (response?.address !== undefined) {
        setComponentState('LoadedSuccessful');
        if (!isEdit) {
          trackEvent('address-screen-completed-regi');
          navigate('/complete-sign-up/user-avatar');
        } else if (onSubmitted) {
          onSubmitted();
        }
        return Promise.resolve();
      }
      handleError('Address was rejected by the server');
      return Promise.reject();
    } catch (error) {
      handleError('An error happens during the setting property information process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <div className={classNames('property-form', className)}>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl
            label="Address line 1"
            name="addressLine1"
            className="form__row"
            disabled={formik.isSubmitting}
            isAutoFocus
          />
          {!isEdit && (
            <InputControl
              label="Address line 2"
              name="addressLine2"
              className="form__row"
              disabled={formik.isSubmitting}
            />
          )}
          <InputControl label="City" name="city" className="form__row" disabled={formik.isSubmitting} />
          <InputControl label="State" name="state" className="form__row" disabled={formik.isSubmitting} />
          <InputControl
            label="ZIP / Postcode "
            name="postalCode"
            className="form__row"
            disabled={formik.isSubmitting}
          />
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: formik.isSubmitting || componentState === 'Loading',
            })}
            type="submit"
            disabled={
              formik.touched.addressLine1 &&
              formik.touched.city &&
              formik.touched.state &&
              formik.touched.postalCode &&
              !formik.isValid
            }
          >
            {isEdit ? 'Save' : 'Next'}
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">Error: {errorMessageRef.current}</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </div>
  );
};

export default PropertyInfoForm;

type Props = {
  className?: string;
  editDate?: FormType;
  onSubmitted?: () => void;
};
