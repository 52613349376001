import React, { useState } from 'react';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import useAuth from '../../../../store/auth/hooks/useAuth';
import { useAppDispatch } from '../../../../store';
import { updateUserEmail } from '../../../../store/auth/auth-store';
import { showToast } from '../../../../store/app/app-store';

import InputControl from '../../../../../shared/components/InputControl/InputControl';
import SectionHeader from '../../../../../shared/components/SectionHeader/SectionHeader';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';

type FormType = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('It should be valid email address'),
});

const EmailForm = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const [componentState, setComponentState] = useState<ApiInteractionState>();

  const initialValues: FormType = {
    email: user?.email ?? '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      updateEmail(values.email);
    },
  });

  const updateEmail = async (email: string) => {
    setComponentState('Loading');
    try {
      await dispatch(updateUserEmail({ email })).unwrap();
      setComponentState('LoadedSuccessful');
      dispatch(showToast({ message: 'Your email was successfully updated' }));
    } catch (error: any) {
      setComponentState('Error');
    }
  };

  return (
    <div className={classNames(className)}>
      <SectionHeader className="mb2">Your Email</SectionHeader>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl type="email" label="Email" name="email" className="form__row" disabled={formik.isSubmitting} />
          <button
            className={classNames('form__submit button button_inline', {
              button_loading: componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Update
          </button>
          <AnimateHeight duration={350} height={componentState === 'LoadedSuccessful' ? 'auto' : 0}>
            <div className="form__api-message form__api-message_success">Successfully Updated</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </div>
  );
};

type Props = {
  className?: string;
};

export default EmailForm;
