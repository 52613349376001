import { store } from '../../app/store';
import { showUnblockUIToaster } from '../../app/store/home/home-store';

const DELAY_BETWEEN_CHECKS_IMAGE = 600;
const DELAY_BETWEEN_CHECKS_VIDEO = 1000;
const MAX_ATTEMPTS = 40;
const WARN_ATTEMPTS = 10;

const testResource = (url: string, attempt: number): Promise<boolean> => {
  const concatenator = url.includes('?') ? '&' : '?';

  return fetch(`${url}${concatenator}attempt=${attempt}`, {
    method: 'HEAD',
  })
    .then((r) => {
      // eslint-disable-next-line no-magic-numbers
      return !(r.status === 404);
    })
    .catch(() => false);
};

const delay = (pauseValue: number): Promise<undefined> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, pauseValue);
  });
};

const checkResource = async (
  url: string,
  resourceType: 'image' | 'video' = 'image',
  maxAttempts = MAX_ATTEMPTS,
): Promise<boolean> => {
  // eslint-disable-next-line functional/no-let
  let attempt = 0;
  // eslint-disable-next-line functional/no-let
  let wasUIBlockingTooLong = false;
  const isImage = resourceType === 'image';
  while (attempt < maxAttempts) {
    const newUrl = url.replace('https://media-dev.alcoveapp.co', 'https://alcove-media-dev.s3.us-east-2.amazonaws.com');
    // eslint-disable-next-line no-await-in-loop
    const isReady = await testResource(newUrl, attempt);

    if (isReady) {
      return true;
    }
    attempt += 1;
    if (attempt > WARN_ATTEMPTS && !wasUIBlockingTooLong) {
      store.dispatch(showUnblockUIToaster());
      wasUIBlockingTooLong = true;
    }
    if (attempt < maxAttempts) {
      // eslint-disable-next-line no-await-in-loop
      await delay(isImage ? DELAY_BETWEEN_CHECKS_IMAGE : DELAY_BETWEEN_CHECKS_VIDEO);
    }
  }
  return false;
};

const memoCheckResource = () => {
  const operationsInProgress = new Map<string, Promise<boolean>>();
  return (task: ResourceUnderCheck) => {
    if (operationsInProgress.has(task.url)) {
      return operationsInProgress.get(task.url) as Promise<boolean>;
    }
    const newOperation = checkResource(task.url, task.resourceType, task.maxAttempts);
    newOperation.then(() => {
      operationsInProgress.delete(task.url);
    });
    operationsInProgress.set(task.url, newOperation);
    return newOperation;
  };
};

const resourceChecker = memoCheckResource();

export const waitResourceReadiness = async (
  url: string,
  resourceType: 'image' | 'video' = 'image',
  maxAttempts = MAX_ATTEMPTS,
): Promise<boolean> => {
  return resourceChecker({ url, resourceType, maxAttempts });
};

type ResourceUnderCheck = {
  readonly url: string;
  readonly resourceType: 'image' | 'video';
  readonly maxAttempts: number;
};
