import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../../store';
import BackButton from '../../components/BackButton/BackButton';

import { trackEvent } from '../../../../../setupAnalytics';

const SignUpStep2 = () => {
  const autoFocusElementRef = useRef<HTMLAnchorElement>(null);
  const { registrationForm } = useSelector((state: RootState) => ({
    registrationForm: state.authReducer.registrationForm,
  }));

  useEffect(() => {
    if (autoFocusElementRef.current) {
      autoFocusElementRef.current.focus();
    }
  }, []);

  return (
    <section className="layout__sign-up">
      <BackButton to="../" />
      <h1 className="layout__sign-up-title layout__sign-up-title_alone">
        {registrationForm?.username?.firstName},
        <br />
        Welcome to the
        <br />
        Home Guide ROKU
        <br />
        application.
      </h1>
      <div className="mb4">
        <Link
          to="/sign-up/email"
          className="form__submit button button_inline"
          ref={autoFocusElementRef}
          onClick={() => {
            trackEvent('welcome-screen-viewed-regi ');
          }}
        >
          Next
        </Link>
      </div>
    </section>
  );
};

export default SignUpStep2;
