import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppToast, ToastType, ToastButton } from './models/appToast';

type AppState = {
  toasts: readonly AppToast[];
};

const initialState: AppState = {
  toasts: [],
};

const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showToast: (
      state,
      action: PayloadAction<{ message: string; type?: ToastType; noAutoClose?: boolean; button?: ToastButton }>,
    ) => {
      const hasToasts = state.toasts.length > 0;
      const nextId = hasToasts ? state.toasts[state.toasts.length - 1].id + 1 : 1;

      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: nextId,
            message: action.payload.message,
            type: action.payload.type ?? 'Success',
            noAutoClose: action.payload.noAutoClose,
            button: action.payload.button,
          },
        ],
      };
    },

    closeToastById: (state, action: PayloadAction<{ id: number }>) => ({
      ...state,
      toasts: state.toasts.filter((item) => item.id !== action.payload.id),
    }),
  },
});

export const { showToast, closeToastById } = appReducer.actions;

export default appReducer.reducer;
