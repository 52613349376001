import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useLocationHistory = () => {
  const location = useLocation();
  const [previousUrl, setPreviousUrl] = useState<string | undefined>();

  useEffect(() => {
    setPreviousUrl(location.pathname);
  }, [location]);

  return {
    currentUrl: location.pathname,
    previousUrl,
  };
};
