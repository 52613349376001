import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useFormik, FormikProvider, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import AnimateHeight from 'react-animate-height';
import { useAppDispatch } from '../../../../store';
import { updateHomeGreetings } from '../../../../store/home/home-store';
import './faq-form.scss';

import InputControl from '../../../../../shared/components/InputControl/InputControl';
import TextareaControl from '../../../../../shared/components/TextareaControl/TextareaControl';
import SvgIcon from '../../../../../shared/components/SvgIcon/SvgIcon';

import { MessagesDto } from '../../../../store/home/models/messagesDto';
import { FaqDto } from '../../../../store/home/models/faqDto';
import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { UpdateGreetingRequest } from '../../../../store/home/models/updateGreetingRequest';
import { trackEvent } from '../../../../../setupAnalytics';

type FormType = {
  faq: readonly FaqDto[];
};

const MAX_ANSWER_LENGTH = 500;
const MAX_FAQ_LENGTH = 12;

const FaqForm = ({ data, guid, onSubmit }: Props) => {
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const [expandedItem, setExpandedItem] = useState<number | undefined>(0);
  const errorMessageRef = useRef<string>();
  const dispatch = useAppDispatch();

  const initialValues: FormType = {
    faq: data?.faq ?? [],
  };

  const validationSchema = Yup.object().shape({
    faq: Yup.array()
      .of(
        Yup.object().shape({
          body: Yup.string()
            .max(MAX_ANSWER_LENGTH, `Answer length should be less or equal ${MAX_ANSWER_LENGTH}`)
            .required('Required'),
          title: Yup.string().required('Required'),
        }),
      )
      .required('Required')
      .min(1, 'You should specify minimum one answer and question')
      .max(MAX_FAQ_LENGTH, `Maximum faq items should be less then ${MAX_FAQ_LENGTH}`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!guid || data === undefined) {
        return undefined;
      }
      const request: UpdateGreetingRequest = {
        messages: {
          faq: values.faq,
          greeting: data.greeting,
          needToKnow: data.needToKnow,
          thingsToDo: data.thingsToDo,
        },
        homeGiud: guid,
      };
      return submitForm(request);
    },
  });

  const submitForm = async (values: UpdateGreetingRequest) => {
    setComponentState('Loading');
    try {
      await dispatch(updateHomeGreetings(values)).unwrap();
      setComponentState('LoadedSuccessful');
      if (isFaqItemAdded(values.messages.faq, data?.faq)) {
        trackEvent('faq-added');
      }
      onSubmit();
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the saving process', error);
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  const isFaqItemAdded = (newFaq: readonly FaqDto[], oldFaq: readonly FaqDto[] | undefined) => {
    return (oldFaq?.length ?? 0) < newFaq.length;
  };

  return (
    <section className="layout__step-form mb4">
      <FormikProvider value={formik}>
        <Form className="form">
          <div className="layout__note mb2">Add topics or questions along with the answers and details for each.</div>
          <FieldArray name="faq">
            {({ push, remove }) => (
              <>
                {formik.values.faq.length > 0 &&
                  formik.values.faq.map((faq, index) => (
                    <section
                      className={classNames('faq-form__item', {
                        'faq-form__item_error': faq.title.length === 0 || faq.body.length === 0,
                      })}
                      key={index}
                    >
                      <div className="faq-form__title-box">
                        <InputControl
                          label="Question"
                          name={`faq[${index}].title`}
                          className="faq-form__input"
                          disabled={formik.isSubmitting}
                          onFocus={() => {
                            setExpandedItem(index);
                          }}
                        />
                        <button
                          className="faq-form__expand-button"
                          tabIndex={-1}
                          type="button"
                          onClick={() => {
                            setExpandedItem(expandedItem === index ? undefined : index);
                          }}
                        >
                          <SvgIcon
                            name="back"
                            className={classNames('faq-form__expand-icon', {
                              'faq-form__expand-icon_active': expandedItem === index,
                            })}
                          />
                        </button>
                        <button
                          className="faq-form__delete-button"
                          type="button"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                      <AnimateHeight duration={350} height={expandedItem === index ? 'auto' : 0}>
                        <aside
                          className={classNames('faq-form__body', {
                            'faq-form__body_expanded': expandedItem === index,
                          })}
                        >
                          <div className="layout__note faq-form__symbol-counter">
                            {formik.values.faq[index].body.length} of {MAX_ANSWER_LENGTH} characters
                          </div>
                          <TextareaControl
                            label="Answer"
                            name={`faq[${index}].body`}
                            className="faq-form__textarea"
                            disabled={formik.isSubmitting}
                          />
                        </aside>
                      </AnimateHeight>
                    </section>
                  ))}
                <button
                  type="button"
                  className="button faq-form__add-button mb4"
                  onClick={() =>
                    push({
                      body: '',
                      title: '',
                    })
                  }
                >
                  Add new FAQ
                  <SvgIcon name="plus" className="faq-form__add-icon" />
                </button>
              </>
            )}
          </FieldArray>

          <button
            className={classNames('form__submit button button_inline', {
              button_loading: formik.isSubmitting || componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.touched && !formik.isValid}
          >
            Save
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">Error: {errorMessageRef.current}</div>
          </AnimateHeight>
          <AnimateHeight duration={350} height={componentState === 'LoadedSuccessful' ? 'auto' : 0}>
            <div className="form__api-message form__api-message_success">Successfully Updated</div>
          </AnimateHeight>
        </Form>
      </FormikProvider>
    </section>
  );
};

type Props = {
  data?: MessagesDto;
  guid?: string;
  onSubmit: () => void;
};

export default FaqForm;
