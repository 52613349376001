import { useEffect, useState } from 'react';
import useAuth from '../../app/store/auth/hooks/useAuth';

export const useFreshworkWidget = () => {
  const { isAuth, user } = useAuth();
  const [isFreshWorkNameSet, setIsFreshWorkNameSet] = useState<boolean>();

  useEffect(() => {
    setIsFreshWorkNameSet(isAuth);
  }, [isAuth]);

  useEffect(() => {
    if (isFreshWorkNameSet === undefined) {
      return;
    }
    if (isAuth) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
        email: user?.email ?? '',
      });
      return;
    }
    window.FreshworksWidget('identify', 'ticketForm', { name: '', email: '' });
  }, [isFreshWorkNameSet]);
};
