import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { useFormik, FormikProvider } from 'formik';
import AnimateHeight from 'react-animate-height';
import * as Yup from 'yup';
import { useAppDispatch } from '../../../../store';
import useAuth from '../../../../store/auth/hooks/useAuth';
import { useMount } from '../../../../../shared/hooks/useMount';
import { recoverUserPassword } from '../../../../store/auth/auth-store';
import { isErrorResponse } from '../../../../../shared/guards/isErrorResponse';

import InputControl from '../../../../../shared/components/InputControl/InputControl';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';

type FormType = {
  email: string;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email('Should be a valid email address'),
});

const initialValues: FormType = {
  email: '',
};

const PasswordRecoveryForm = ({ className, onEmailSent }: Props) => {
  const errorMessageRef = useRef<string>();
  const [componentState, setComponentState] = useState<ApiInteractionState>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      submitForm(values);
    },
  });

  useMount(() => {
    if (isAuth) {
      navigate('/');
    }
  });

  const submitForm = async ({ email }: FormType) => {
    setComponentState('Loading');
    try {
      const response = await dispatch(recoverUserPassword({ email })).unwrap();
      if (isErrorResponse(response)) {
        handleError(response.error);
        return Promise.reject();
      }
      setComponentState('LoadedSuccessful');
      onEmailSent();
      return Promise.resolve();
    } catch (error) {
      handleError('An error happens during the password recovery process');
      return Promise.reject();
    }
  };

  const handleError = (message: string, error?: any) => {
    console.warn(error);
    // eslint-disable-next-line functional/immutable-data
    errorMessageRef.current = message;
    setComponentState('Error');
  };

  return (
    <div className={classNames('password-recovery-form', className)}>
      <FormikProvider value={formik}>
        <form className="form" onSubmit={formik.handleSubmit}>
          <InputControl
            label="Email Address"
            name="email"
            className="form__row"
            disabled={formik.isSubmitting}
            isAutocompleteOff
          />
          <button
            className={classNames('form__submit button button', {
              button_loading: componentState === 'Loading',
            })}
            type="submit"
            disabled={formik.touched && !formik.isValid}
          >
            Send email
          </button>
          <AnimateHeight duration={350} height={componentState === 'Error' ? 'auto' : 0}>
            <div className="form__api-message">Error: {errorMessageRef.current}</div>
          </AnimateHeight>
        </form>
      </FormikProvider>
    </div>
  );
};

type Props = {
  className?: string;
  onEmailSent: () => void;
};

export default PasswordRecoveryForm;
