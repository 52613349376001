import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import AnimateHeight from 'react-animate-height';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../../../store/auth/hooks/useAuth';
import { useAppDispatch } from '../../../../store';
import { updateUserProfile } from '../../../../store/auth/auth-store';
import { getMediaUploadLink, uploadMediaS3 } from '../../../../store/home/home-store';
import { waitResourceReadiness } from '../../../../../shared/utils/waitResourceReadiness';
import './user-avatar.scss';

import { ApiInteractionState } from '../../../../../shared/models/apiInteractionState';
import { trackEvent } from '../../../../../setupAnalytics';

type FormType = {
  profileUrl: string;
};

const validationSchema = Yup.object().shape({
  profileUrl: Yup.string().required('Profile image is required'),
});

const UserAvatar = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const [uploadingImageState, setUploadingImageState] = useState<ApiInteractionState>();
  const [updatingProfileState, setUpdatingProfileState] = useState<ApiInteractionState>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const errorImageUploadingMessageRef = useRef<string>();
  const errorMessageRef = useRef<string>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.profileImageUrl) {
      return;
    }
    setProfileImageUrl(user.profileImageUrl);
  }, [user?.profileImageUrl]);

  useEffect(() => {
    if (!profileImageUrl) {
      return;
    }
    formik.setFieldValue('profileUrl', profileImageUrl);
  }, [profileImageUrl]);

  const initialValues: FormType = {
    profileUrl: profileImageUrl ?? '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      updateProfile(values.profileUrl);
    },
  });

  const handleChangeAvatar = (event: any) => {
    if (user === undefined) {
      return;
    }
    if (event.target.files.length <= 0) {
      return;
    }
    const formData = new FormData();
    const file: File = event.target.files[0];
    formData.append('profile', file);

    dispatch(getMediaUploadLink({ fileType: 'profile' }))
      .unwrap()
      .then((response) => {
        uploadAvatar(formData, response.link, response.resultFilename);
      });
  };

  const uploadAvatar = async (formData: FormData, uploadUrl: string, userProfileUrl: string) => {
    setUploadingImageState('Loading');
    try {
      await dispatch(uploadMediaS3({ link: uploadUrl, data: formData.get('profile') })).unwrap();
      await waitResourceReadiness(userProfileUrl);
      setProfileImageUrl(userProfileUrl);
      setUploadingImageState('LoadedSuccessful');
    } catch (error: any) {
      // eslint-disable-next-line functional/immutable-data
      errorImageUploadingMessageRef.current = error?.message ?? 'Unknown image uploading error';
      setUploadingImageState('Error');
    }
  };

  const updateProfile = async (profileUrl: string) => {
    if (user === undefined) {
      return Promise.reject();
    }
    setUpdatingProfileState('Loading');
    try {
      await dispatch(
        updateUserProfile({ name: `${user.firstName} ${user.lastName}`, metadata: { profile_url: profileUrl } }),
      ).unwrap();
      trackEvent('profile-pic-uploaded-regi');
      navigate('/complete-sign-up/link-device');
      return Promise.resolve();
    } catch (error: any) {
      // eslint-disable-next-line functional/immutable-data
      errorMessageRef.current = error?.message ?? 'Unknown error';
      setUpdatingProfileState('Error');
      return Promise.reject();
    }
  };

  return (
    <section className="layout__sign-up layout__sign-up_big-form">
      <div>
        <h1 className="layout__sign-up-title">You’re doing great!</h1>
        <div className="layout__sign-up-text mb4">Upload a profile picture</div>
        <div className="user-avatar__message">This photo will be visible to your guests.</div>
        <section className="user-avatar">
          <label
            className="user-avatar__preview"
            style={
              profileImageUrl
                ? {
                    backgroundImage: `url(${profileImageUrl})`,
                  }
                : undefined
            }
            title={user?.profileImageUrl ? 'Change Profile Image' : 'Upload Profile Image'}
          >
            <input type="file" onChange={handleChangeAvatar} className="visually-hidden" />
          </label>
          <AnimateHeight
            duration={350}
            height={updatingProfileState === 'Error' || (formik.touched && !formik.isValid) ? 'auto' : 0}
          >
            {updatingProfileState === 'Error' && (
              <div className="form__api-message">Error: {errorMessageRef.current}</div>
            )}
            <div className="form__api-message">{formik.errors.profileUrl}</div>
          </AnimateHeight>
          <div className="user-avatar__button-box">
            <label
              className={classNames('button button_inline user-avatar__button', {
                button_loading: uploadingImageState === 'Loading',
              })}
            >
              <input type="file" onChange={handleChangeAvatar} className="visually-hidden" />
              UPLOAD
            </label>
            <FormikProvider value={formik}>
              <form className="form user-avatar__button" onSubmit={formik.handleSubmit}>
                <button
                  className={classNames('button button_inline', {
                    button_loading: updatingProfileState === 'Loading',
                  })}
                  type="submit"
                  disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                >
                  Next
                </button>
                <Field name="profileUrl" className="visually-hidden" />
              </form>
            </FormikProvider>
          </div>
        </section>
      </div>
    </section>
  );
};

export default UserAvatar;
