import React from 'react';
import { Link } from 'react-router-dom';
import './app-header.scss';

import AppHeaderUserMenu from '../AppHeaderUserMenu/AppHeaderUserMenu';

const AppHeader = () => {
  return (
    <header className="app-header">
      <div className="container">
        <section className="app-header__box">
          <Link to="/" className="app-header__logo logo" title="Open front page">
            Alcove Home Guide
          </Link>
          <AppHeaderUserMenu className="app-header__user-menu" />
        </section>
      </div>
    </header>
  );
};

export default AppHeader;
