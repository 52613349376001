import React from 'react';
import classNames from 'classnames';
import './loader-placeholder.scss';

import SvgIcon from '../SvgIcon/SvgIcon';

const LoaderPlaceholder = ({ type, className }: Props) => {
  return (
    <div className={classNames('loader-placeholder', className, { 'loader-placeholder_small': type === 'sm' })}>
      <SvgIcon name="spinner" className="loader-placeholder__spinner" />
    </div>
  );
};

export type Props = {
  type?: 'sm' | 'default';
  className?: string;
};

export default LoaderPlaceholder;
