import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { PromoImage } from '../../../../../shared/models/promoImage';
import PageFrame from '../../../../../shared/components/PageFrame/PageFrame';
import PasswordRecoveryForm from '../../components/PasswordRecoveryForm/PasswordRecoveryForm';
import BackButton from '../../components/BackButton/BackButton';

const PasswordRecovery = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleEmailSent = () => {
    setIsEmailSent(true);
  };

  return (
    <div className="container">
      <PageFrame imageUrl={PromoImage.Evening}>
        <section className="layout__sign-up layout__sign-up_small-form">
          <BackButton to="/login">Login</BackButton>
          {isEmailSent ? (
            <>
              <h1 className="layout__sign-up-title">Check your inbox</h1>
              <div className="layout__sign-up-text mb10">We’ll send you an email to reset the password.</div>
              <Link to="/login" className="form__submit button">
                Login screen
              </Link>
            </>
          ) : (
            <>
              <h1 className="layout__sign-up-title">Forgot Password</h1>
              <div className="layout__sign-up-text mb4">We’ll send you an email to reset the password.</div>
              <PasswordRecoveryForm className="" onEmailSent={handleEmailSent} />
            </>
          )}
        </section>
      </PageFrame>
    </div>
  );
};

export default PasswordRecovery;
